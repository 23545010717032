<div class="dashboard-header fixed-top">
    <nav class="navbar navbar-expand-lg bg-white">
        <button type="button" class="toggle-btn hamburger-btn" (click)="openSidebar()"><i><img
                    src="assets/img/icons/hamburger_icon.svg" alt="" /></i></button>
        <div class="navbar-collapse" id="navbarSupportedContent">
            <!-- <form class="ml-auto search_form">
                <input type="text" class="form-control" placeholder="Search" />
                <button><img src="assets/img/icons/search_icon.svg" alt="" /></button>
            </form> -->

            <ul class="navbar-nav ml-auto navbar-right-top">
                <li class="nav-item dropdown nav-user">
                    <div class="d-flex align-items-center nav-link nav-user-img">
                        <div class="nav-user-info">
                            <h6 class="mb-0 nav-user-name">{{admin?.name}} </h6>
                            <span class="status"></span><span class="pull-right">Admin</span>
                        </div>
                        <a href="javascript:void(0)" id="navbarDropdownMenuLink2" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            <img [src]="admin?.profilePicture || 'assets/img/user_logo.png'" alt=""
                                class="user-avatar-md rounded-circle"><i class="fa fa-caret-down ml-2"
                                aria-hidden="true"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right nav-user-dropdown"
                            aria-labelledby="navbarDropdownMenuLink2">
                            <a class="dropdown-item" [routerLink]="LINKS.ACCOUNT_SETTINGS" href="javascript:void(0)"><i
                                    class="fa fa-user mr-2"></i>Account Settings</a>
                            <a class="dropdown-item" href="javascript:void(0)" (click)="logout()"><i
                                    class="fa fa-power-off mr-2"></i>Logout</a>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</div>