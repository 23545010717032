<div class="front-forms">
    <div class="container-fluid">
        <div class="login_box">
            <div class="front-logo text-center">
                <img src="assets/img/logo.png" alt="KopShop" />
            </div>

            <div *ngIf="invalid_token" class="form-box invalid_token">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                <span>
                    {{content}}
                </span>
            </div>

            <form class="form-box" *ngIf="!invalid_token" [formGroup]="resetPasswordForm" (submit)="resetPassword()">
                <h2 class="text-center mb-0">Reset password</h2>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <img src="assets/img/icons/password.svg" alt="KopShop" width="22" />
                                    </span>
                                </div>
                                <input formControlName="newPassword" type="password" class="form-control"
                                    placeholder="Enter new password">
                            </div>
                            <div *ngIf="
                            resetPasswordForm.controls['newPassword'].hasError('required') &&
                            resetPasswordForm.controls['newPassword'].touched" class="text-danger">
                                {{FORM_ERRORS.NEW_PASSWORD_REQUIRED}}
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <img src="assets/img/icons/password.svg" alt="KopShop" width="22" />
                                    </span>
                                </div>
                                <input formControlName="confirmPassword" type="password" class="form-control"
                                    placeholder="Confirm new password">
                            </div>
                            <div *ngIf="
                            resetPasswordForm.controls['confirmPassword'].hasError('required') &&
                            resetPasswordForm.controls['confirmPassword'].touched" class="text-danger">
                                {{FORM_ERRORS.CONFIRM_PASSWORD_REQUIRED}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="mt-2 mt-lg-3">
                            <button class="w-100 btn btn-lg btn-primary text-capitalize" type="submit">Change
                                password</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>