import { SWAL_TITLES, REGEX, ERROR_MSG } from './../../constants';
import { ApiService } from './../../core/services/api.service';
import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SwalService } from 'src/app/common/dialogue-services/swal.service';
import * as $ from 'jquery';
import { ToastrService } from 'src/app/common/toastr/toastr.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {

  closeResult;
  categories;
  uploading = false;
  fileName;
  loading = false;
  selectedCategory;

  public categoryForm: FormGroup;

  constructor(private modalService: NgbModal, private toastr: ToastrService, private apiService: ApiService, private formBuilder: FormBuilder, private swal: SwalService) {
    this.categoryForm = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required, Validators.pattern(REGEX.NO_WHITESPACE_REGEX)])],
      url: ['', Validators.required]
    })
  }

  ngOnInit(): void {
    this.getCategories();
  }

  upload(event) {
    this.uploading = true;
    this.categoryForm.controls['url'].patchValue('');
    let file = event.target.files[0];
    this.apiService.fileUpload(file).subscribe(res => {
      $('.file-custom').text(file.name);
      this.uploading = false;
      this.categoryForm.controls['url'].patchValue(res.data.Location);
    })
  }


  addCategory() {
    if (this.categoryForm.controls['name'].value && !this.categoryForm.controls['url'].value) {
      this.toastr.showToastr({ type: 'error', message: ERROR_MSG.PLEASE_UPLOAD_MEDIA });
      return;
    } else {
      if (this.categoryForm.valid) {
        this.loading = true;
        this.apiService.addCategory(this.categoryForm.value).subscribe(res => {
          this.categoryForm.reset();
          this.loading = false;
          this.getCategories();
          this.modalService.dismissAll()
        })
      } else {
        Object.keys(this.categoryForm.controls).forEach(key => {
          this.categoryForm.controls[key].markAsTouched({ onlySelf: true })
        })
      }
    }
  }

  async openUpdate(content, data) {
    this.selectedCategory = data;
    this.categoryForm.controls['name'].patchValue(data.name);
    this.categoryForm.controls['url'].patchValue(data.url);
    let modal_data = await this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result;
    $('.file-custom').text(this.selectedCategory.url);
  }

  getCategories() {
    this.loading = true;
    this.apiService.getCategories().subscribe(res => {
      this.loading = false;
      this.categories = res.data;
    })
  }

  async deleteCategory(id) {
    let swal_data = await this.swal.deleteSwal(SWAL_TITLES.DELETE_MESSAGE);
    if (swal_data.value) {
      this.apiService.deleteCategory(id).subscribe(res => {
        this.getCategories();
      })
    }
  }

  updateCategory() {
    if (this.categoryForm.valid) {
      const obj = {
        name: this.categoryForm.controls['name'].value,
        url: this.categoryForm.controls['url'].value,
        id: this.selectedCategory._id
      }
      this.apiService.udpateCategory(obj).subscribe(res => {
        console.log(res);
        this.modalService.dismissAll();
        this.getCategories();
      })
    } else {
      Object.keys(this.categoryForm.controls).forEach(key => {
        this.categoryForm.controls[key].markAsTouched({ onlySelf: true })
      })
    }
  }

  open(content) {
    this.categoryForm.reset();
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
