import { SWAL_TITLES } from './../../constants';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwalService } from 'src/app/common/dialogue-services/swal.service';
import { ApiService } from 'src/app/core/services/api.service';

@Component({
  selector: 'app-domain',
  templateUrl: './domain.component.html',
  styleUrls: ['./domain.component.css']
})
export class DomainComponent implements OnInit {

  public domainForm: FormGroup;
  domains;
  loading = false;

  editFlag = false;

  domainToUpdate;

  constructor(private modalService: NgbModal, private apiService: ApiService, private formBuilder: FormBuilder, private swal: SwalService) {
    this.domainForm = this.formBuilder.group({
      domain: ['', Validators.required]
    })
  }

  ngOnInit(): void {
    this.getDomains()
  }

  submitOperation() {
    if (this.editFlag) {
      this.updateDomain()
    } else {
      this.addDomain()
    }
  }

  openModal(content, editFlag, item) {
    if (editFlag) {
      this.editFlag = true;
      this.domainToUpdate = item;
      this.domainForm.controls['domain'].patchValue(item.domain);
    } else {
      this.editFlag = false;
      this.domainToUpdate = item;
      this.domainForm.reset();
    }
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(result => {
    }, (reason) => {
    });
  }

  hideModal() {
    this.modalService.dismissAll()
  }

  getDomains() {
    this.loading = true;
    this.apiService.getDomains().subscribe(res => {
      this.domains = res.data;
      this.loading = false;
    })
  }

  addDomain() {
    this.domainForm.controls['domain'].patchValue(this.domainForm.value.domain.trim());
    if (this.domainForm.valid) {
      this.loading = true;
      this.apiService.addDomain(this.domainForm.value).subscribe(res => {
        this.getDomains();
        this.loading = false;
        this.modalService.dismissAll()
        this.domainForm.reset();
      }, err => {
        this.loading = false;
      });
    } else {
      Object.keys(this.domainForm.controls).forEach(key => {
        this.domainForm.controls[key].markAsTouched({ onlySelf: true });
      })
    }
  }

  updateDomain() {
    this.domainForm.controls['domain'].patchValue(this.domainForm.value.domain);
    if (this.domainForm.valid) {
      if (this.domainToUpdate.domain === this.domainForm.controls['domain'].value.trim()) {
        this.modalService.dismissAll();
        this.domainForm.reset();
        this.editFlag = false;
        this.domainToUpdate = null;
        this.loading = false;
        this.getDomains()
      } else {
        this.loading = true;
        this.apiService.updateDomain({
          domain: this.domainForm.controls['domain'].value,
          id: this.domainToUpdate._id
        }).subscribe(res => {
          this.modalService.dismissAll();
          this.domainForm.reset();
          this.editFlag = false;
          this.domainToUpdate = null;
          this.loading = false;
          this.getDomains()
        });
      }
    } else {
      Object.keys(this.domainForm.controls).forEach(key => {
        this.domainForm.controls[key].markAsTouched({ onlySelf: true });
      })
    }
  }

  async deleteDomain(id) {
    let data = await this.swal.deleteSwal(SWAL_TITLES.DELETE_MESSAGE);
    if (data.value) {
      this.apiService.deleteDomain(id).subscribe(res => {
        this.getDomains();
      })
    }
  }

}
