<!-- Sidebar Nav -->
<nav id="sidebarMenu" (click)="toggleSideClass()" [class]="activeClassSidebar">
    <div class="menu-group">
        <a class="navbar-brand" href="javascript:void(0)">
            <img src="assets/img/logo.png" alt="KopShop" />
        </a>

        <!-- Start Sidebar Navigation -->
        <div class="main-menu">
            <ul id="side-main-menu" class="side-menu list-unstyled">
                <li (click)="toggleSidebar()" *ngFor="let item of menu"><a href="javascript:void(0)"
                        [routerLink]="item.link" routerLinkActive="active-sidebar"><i><img [src]="item.icon"
                                [alt]="item.title" /></i>
                        {{item.title}} </a></li>
            </ul>
            <div class="logout">
                <a href="javascript:void(0)" (click)="logout()"><i><img src="assets/img/icons/logout_icon.svg"
                            alt="logout" /></i> Logout</a>
            </div>
        </div>
        <!-- End Sidebar Navigation-->
    </div>
</nav>