<div class="card user_table default_block box_vh">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3 class="">Account Settings</h3>
    </div>

    <div class="row">
        <div class="card-body col-md-6 col-12 border-right my-md-3">

            <form [formGroup]="accountForm">
                <div class="account">
                    <div class="form-group upload_img" style="text-align: center;">
                        <img [src]="admin?.profilePicture || 'assets/img/user_logo.png'">
                        <input title="Change photo" style="cursor: pointer;" type="file"
                            (change)="uploadNewProfile($event)">
                    </div>
                </div>
                <div class="form-group">
                    <label for="">Account Name: </label>
                    <input class="form-control" (blur)="nameChanged($event)" formControlName="name">
                </div>
            </form>
        </div>

        <div class="card-body col-md-6 col-12">
            <div class="form_pages">
                <form [formGroup]="changePasswordForm" (submit)="changePassword()">

                    <div class="form-group">
                        <label>Current Password</label>
                        <input type="password" class="form-control" placeholder="Enter current password"
                            formControlName="oldPassword">
                        <div class="error_box text-danger"
                            *ngIf="changePasswordForm.controls['oldPassword'].hasError('required') && changePasswordForm.controls['oldPassword'].touched">
                            Please enter current password
                        </div>
                    </div>
                    <div class="form-group">
                        <label>New Password</label>
                        <input type="password" class="form-control" placeholder="Enter new password"
                            formControlName="newPassword">
                        <div class="error_box text-danger"
                            *ngIf="changePasswordForm.controls['newPassword'].hasError('required') && changePasswordForm.controls['newPassword'].touched">
                            Please enter new password
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Confirm Password</label>
                        <input type="password" class="form-control" placeholder="Re-enter new password"
                            formControlName="confirmNewPassword">
                        <div class="error_box text-danger"
                            *ngIf="changePasswordForm.controls['confirmNewPassword'].hasError('required') && changePasswordForm.controls['confirmNewPassword'].touched">
                            Please re-enter new password
                        </div>
                    </div>
                    <div class="text-center mt-4 mt-md-5">
                        <button type="submit" [disabled]="loading" class="btn btn-lg btn-primary">
                            <span *ngIf="!loading">Submit</span>
                            <span *ngIf="loading">Submitting...</span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>