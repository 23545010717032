import { AUTH_PATHS } from './../../constants';
import { SwalService } from './../../common/dialogue-services/swal.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from '../../common/toastr/toastr.service';
import { SUCCESS_MSG, ERROR_MSG, REGEX } from '../../constants';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-reset-pin',
  templateUrl: './reset-pin.component.html',
  styleUrls: ['./reset-pin.component.css']
})
export class ResetPinComponent implements OnInit {

  token: string = null;
  content;
  invalid_token = false;

  FORM_ERRORS = {
    NEW_PIN_REQUIRED: 'Please enter new PIN',
    CONFIRM_PIN_REQUIRED: 'Please re-enter new PIN',
    MIN_CHAR: 'PIN must be of length 4',
    INVALID_PIN: 'Please enter valid PIN'
  }

  public resetPinForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
    this.resetPinForm = this.fb.group({
      newPin: ['', Validators.compose([Validators.required, Validators.minLength(4), Validators.pattern(REGEX.ONLY_DIGIT)])],
      confirmPin: ['', Validators.compose([Validators.required, Validators.minLength(4), Validators.pattern(REGEX.ONLY_DIGIT)])]
    })
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.token = params['token'];
      this.verifyResetPinToken(this.token);
    })
  }

  verifyResetPinToken(token) {
    this.authService.verifyResetPinToken({ token }).subscribe(res => {
      this.invalid_token = false;
    }, err => {
      if (err.status == 400) {
        this.invalid_token = true;
        this.content = err.error.msg
      }
    })
  }

  resetPin() {
    if (this.resetPinForm.valid) {
      if (this.resetPinForm.controls['newPin'].value === this.resetPinForm.controls['confirmPin'].value) {
        this.authService.resetPin({ ...this.resetPinForm.value, ...{ token: this.token } }).subscribe(res => {
          this.resetPinForm.reset();
          this.router.navigateByUrl(AUTH_PATHS.PIN_RESET_SUCCESSFULLY)
        })
      } else {
        this.toastrService.showToastr({ type: 'error', message: ERROR_MSG.PIN_NOT_MATCHED })
      }
    } else {
      Object.keys(this.resetPinForm.controls).forEach(key => {
        this.resetPinForm.controls[key].markAsTouched({ onlySelf: true })
      })
    }
  }

}
