<div class="row card_blocks my-4">
    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12">
        <div class="card-block">
            <h5>All Users</h5>
            <div class="row align-items-end">
                <div class="col-5">
                    <i class="icon"><img src="assets/img/icons/all_users_icon.svg" alt="users" /></i>
                </div>
                <div class="col-7 text-right">
                    <span>{{dashboardData?.allUsersCount}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12">
        <div class="card-block">
            <h5>Approved Users</h5>
            <div class="row align-items-end">
                <div class="col-5">
                    <i class="icon"><img src="assets/img/icons/approved_users.svg" alt="users" /></i>
                </div>
                <div class="col-7 text-right">
                    <span>{{dashboardData?.approvedUserCount}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12">
        <div class="card-block">
            <h5>User Requests</h5>
            <div class="row align-items-end">
                <div class="col-5">
                    <i class="icon"><img src="assets/img/icons/disapproved_users.svg" alt="users" /></i>
                </div>
                <div class="col-7 text-right">
                    <span>{{dashboardData?.nonApprovedUserCount}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12">
        <div class="card-block">
            <h5>Domains</h5>
            <div class="row align-items-end">
                <div class="col-5">
                    <i class="icon"><img src="/assets/img/icons/domain_icon.svg" alt="users" /></i>
                </div>
                <div class="col-7 text-right">
                    <span>{{dashboardData?.domainCount}}</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div>
    <app-users (userListUpdated)="intialHits()"></app-users>
</div>