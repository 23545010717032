import { PaginationComponent } from './../common/pagination/pagination.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InternalRoutingModule } from './internal-routing.module';
import { InternalComponent } from './internal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NavbarComponent } from './navbar/navbar.component';
import { CategoriesComponent } from './categories/categories.component';
import { UserRequestComponent } from './user-request/user-request.component';
import { FormsComponent } from './forms/forms.component';
import { EvidenceKitsComponent } from './evidence-kits/evidence-kits.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EvidenceComponent } from './evidence/evidence.component';
import { EvidenceListingComponent } from './evidence-listing/evidence-listing.component';
import { EvidenceDetailsComponent } from './evidence-details/evidence-details.component';
import { AddOrEditEvidenceComponent } from './add-or-edit-evidence/add-or-edit-evidence.component';
import { GeneralInformationComponent } from './general-information/general-information.component';
import { UsersComponent } from './users/users.component';
import { DomainComponent } from './domain/domain.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { GeneralInformationFormComponent } from './general-information-form/general-information-form.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@NgModule({
  declarations: [PaginationComponent, InternalComponent, SidebarComponent, NavbarComponent, CategoriesComponent, UserRequestComponent, FormsComponent, EvidenceKitsComponent, DashboardComponent, EvidenceComponent, EvidenceListingComponent, EvidenceDetailsComponent, AddOrEditEvidenceComponent, GeneralInformationComponent, UsersComponent, DomainComponent, ChangePasswordComponent, GeneralInformationFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    PdfViewerModule,
    NgbModule,
    ReactiveFormsModule,
    InternalRoutingModule
  ],
  providers: [],
  exports: []
})
export class InternalModule { }
