import { SWAL_TITLES } from './../../constants';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { SwalService } from 'src/app/common/dialogue-services/swal.service';

@Component({
  selector: 'app-user-request',
  templateUrl: './user-request.component.html',
  styleUrls: ['./user-request.component.css']
})
export class UserRequestComponent implements OnInit {

  userRequests;

  loading = false;

  collectionSize;
  page = 1;
  pageSize = 10;
  skip = 0;
  default_sorting = {
    sortKey: 'createdAt',
    sortDirection: -1
  }

  active_class = "createdAt-1"

  default_pagination = {
    limit: 10,
    skip: 0
  }

  constructor(private apiService: ApiService, private swal: SwalService) { }

  ngOnInit(): void {
    this.getUserRequests({ ...this.default_pagination, ...this.default_sorting });
  }

  async deleteUserRequest(id) {
    let swal_data = await this.swal.deleteSwal(SWAL_TITLES.DELETE_MESSAGE);
    if (swal_data.value) {
      this.loading = true;
      this.apiService.deleteUser(id).subscribe(res => {
        this.getUserRequests({ ...this.default_pagination, ...this.default_sorting });
        this.loading = false;
      })
    }
  }

  sorting(sortKey, sortDirection) {
    this.loading = true;
    this.default_sorting['sortKey'] = sortKey;
    this.default_sorting['sortDirection'] = sortDirection;
    this.skip = (this.page - 1) * this.pageSize;
    this.getUserRequests({ ...{ limit: this.pageSize, skip: this.skip }, ...this.default_sorting });
    this.loading = false;
    this.active_class = `${sortKey}${sortDirection}`
  }

  approveUser(id) {
    this.loading = true;
    this.apiService.approveUser(id).subscribe(res => {
      this.getUserRequests({ ...this.default_pagination, ...this.default_sorting });
      this.loading = false;
    })
  }

  getUserRequests(pagination) {
    this.loading = true;
    this.apiService.getUserRequests(pagination).subscribe(res => {
      this.userRequests = res.data
      this.collectionSize = res.totalCount;
      this.loading = false;
    })
  }

  pageChangeEvent(e) {
    this.loading = true;
    this.page = e;
    this.skip = (this.page - 1) * this.pageSize;
    this.getUserRequests({
      skip: this.skip,
      limit: this.pageSize
    })
    this.loading = false;
  }

}
