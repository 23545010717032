import { NgxSpinnerService } from 'ngx-spinner';
import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import * as $ from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(private router: Router, private loader: NgxSpinnerService) {
    this.threeDotsAction();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0)
      }
    })
  }

  ngOnInit() {
  }

  threeDotsAction() {
    $('.actions').parents('.dots_wrap').toggleClass('show');
  }

}
