import { INTERNAL_PATHS } from './../../constants';
import { Injectable } from '@angular/core';
import { Router, CanActivate, CanDeactivate, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AUTH_PATHS } from '../../constants';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class ExternalAuthguardService implements CanActivate, CanDeactivate<CanComponentDeactivate> {
  constructor(
    public router: Router
  ) {
  }

  canActivate(): boolean {
    if (!this.isAuthenticated()) {
      return true;
    } else {
      this.router.navigateByUrl(`/${INTERNAL_PATHS.ADMIN}`)
      // if (window.confirm('Want to logout ?')) {
      //   localStorage.clear()
      //   this.router.navigateByUrl(`/${AUTH_PATHS.SIGN_IN}`)
      // } else {
      //   return false;
      // }
    }
  }

  canDeactivate(component: CanComponentDeactivate) {
    return component.canDeactivate ? component.canDeactivate() : true;
  }

  isAuthenticated(): boolean {
    const token = localStorage.getItem('accessToken');
    if (token) {
      return true
    } else {
      return false
    }
  }
}
