<div class="card user_table default_block box_vh">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3 class="">Categories</h3>
        <button class="btn btn-md btn-primary" (click)="open(addCategories)">Add New</button>
    </div>

    <div class="card-body">
        <div class="row" *ngIf="categories && categories.length !== 0; else noCategories">
            <ng-container *ngFor="let item of categories">
                <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                    <div class="cat_box text-center purple">
                        <div class="d-flex dots_drop">
                            <a href="#" class="actions" id="drop2" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                                <img src="assets/img/icons/dots_icon.svg" class="img-open" alt="" />
                                <img src="assets/img/icons/close_icon.svg" class="img-close" alt="" />
                            </a>
                            <div class="dropdown-menu shadow" aria-labelledby="drop2">
                                <i (click)="openUpdate(updateCategories, item)"><img
                                        src="assets/img/icons/edit_icon.svg" alt="logout" /></i>
                                <i (click)="deleteCategory(item._id)"><img src="assets/img/icons/delete_icon.svg"
                                        alt="logout" /></i>
                            </div>
                        </div>
                        <i><img [src]="item.url" [alt]="item.name" /></i>
                        <h6>{{item.name}}</h6>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>


<ng-template #addCategories let-modal>
    <div class="modal-header text-center">
        <button type="button" (click)="modal.dismiss('Cross click')" class="close"><img
                src="assets/img/icons/close_icon.svg" alt="" /></button>
        <h3 class="modal-title text-center">Add New Category</h3>
    </div>
    <div class="modal-body">
        <form [formGroup]="categoryForm" (submit)="addCategory()">
            <div class="form-group">
                <label>Category Name</label>
                <input type="text" class="form-control" formControlName="name" maxlength="20" placeholder="Enter name">
                <div class="error_box text-danger"
                    *ngIf="categoryForm.controls['name'].hasError('required') && categoryForm.controls['name'].touched">
                    Please enter category name
                </div>
                <div class="error_box text-danger"
                    *ngIf="categoryForm.controls['name'].hasError('pattern') && categoryForm.controls['name'].touched">
                    Please enter valid category name
                </div>
            </div>
            <div class="form-group">
                <label>Upload Image</label>
                <div class="file mb-4">
                    <input type="file" id="file" accept="image/*" aria-label="File browser example"
                        (change)="upload($event)">
                    <label class="file-custom" for="file">
                        <span class="file-choose">Choose File...</span>
                        <button class="btn">Choose File</button>
                    </label>
                </div>
            </div>

            <div class="btn-full">
                <button type="submit" [disabled]="loading" class="btn btn-lg btn-primary">
                    <ng-container *ngIf="uploading"> Uploading... </ng-container>
                    <ng-container *ngIf="!uploading && !loading"> Submit </ng-container>
                    <ng-container *ngIf="loading"> Submitting... </ng-container>
                </button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #updateCategories let-modal>
    <div class="modal-header text-center">
        <button type="button" (click)="modal.dismiss('Cross click')" class="close"><img
                src="assets/img/icons/close_icon.svg" alt="" /></button>
        <h3 class="modal-title text-center">Update Category</h3>
    </div>
    <div class="modal-body">
        <form [formGroup]="categoryForm" (submit)="updateCategory()">
            <div class="form-group selected_image">
                <!-- <label>Category Image</label> -->
                <div class="file mb-4">
                    <img [src]="selectedCategory.url" />
                </div>
            </div>
            <div class="form-group">
                <label>Category Name</label>
                <input type="text" class="form-control" formControlName="name" maxlength="20" placeholder="Enter name">
                <div class="error_box text-danger"
                    *ngIf="categoryForm.controls['name'].hasError('required') && categoryForm.controls['name'].touched">
                    Please enter category name
                </div>
                <div class="error_box text-danger"
                    *ngIf="categoryForm.controls['name'].hasError('pattern') && categoryForm.controls['name'].touched">
                    Please enter valid category name
                </div>
            </div>
            <div class="form-group">
                <label>Change Image</label>
                <div class="file mb-4">
                    <input type="file" accept="image/*" id="file" aria-label="File browser example"
                        (change)="upload($event)">
                    <label class="file-custom" for="file">
                        <span class="file-choose">Choose File...</span>
                        <button class="btn">Choose File</button>
                    </label>
                </div>
            </div>

            <div class="btn-full">
                <button type="submit" [disabled]="loading" class="btn btn-lg btn-primary">
                    <ng-container *ngIf="uploading"> Uploading... </ng-container>
                    <ng-container *ngIf="!uploading && !loading"> Submit </ng-container>
                    <ng-container *ngIf="loading"> Submitting... </ng-container>
                </button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #noCategories>
    <div *ngIf="!loading">
        <div class="card-body">
            <div class="d-flex align-items-center justify-content-center w-100 flex-column no-result">
                <i><img src="assets/img/icons/no-video_icon.svg" width="80" alt="" /></i>
                <h5 class="mt-3">No Category Added</h5>
            </div>
        </div>
    </div>
</ng-template>