<div class="card user_table default_block box_vh">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3 class="">Forms</h3>
        <button class="btn btn-md btn-primary" type="button" (click)="openModal(addForms, false)">Add New</button>
    </div>

    <div class="card-body" *ngIf="forms?.length > 0;else noCategories">
        <ul class="draggable_block">
            <li class="d-flex list_drag" *ngFor="let item of forms">
                <h5><i><img src="assets/img/icons/drag_icon.svg" width="24" alt="" /></i>{{item.title}}</h5>
                <div class="dots_drop">
                    <a href="#" class="actions" id="drop2" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <img src="assets/img/icons/dots_icon.svg" class="img-open" alt="" />
                        <img src="assets/img/icons/close_icon.svg" class="img-close" alt="" />
                    </a>
                    <div class="dropdown-menu shadow" aria-labelledby="drop2">
                        <i (click)="openModal(addForms, true, item)" href="javacript:void(0)" data-toggle="modal"><img
                                src="assets/img/icons/edit_icon.svg" alt="edit" /></i>
                        <i (click)="deleteForm(item._id)" href="javacript:void(0)" data-toggle="modal"><img
                                src="assets/img/icons/delete_icon.svg" alt="delete" /></i>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>

<ng-template #addForms>
    <div class="modal-header text-center">
        <button type="button" (click)="hideModal()" class="close"><img src="assets/img/icons/close_icon.svg"
                alt="" /></button>
        <h3 class="modal-title text-center">Add Form</h3>
    </div>
    <div class="modal-body">
        <form [formGroup]="formsForm" (submit)="submitOperation()">

            <div *ngIf="editFlag" class="form-group">
                <a [href]="selectedForm.url" target="_blank">View uploaded PDF</a>
            </div>

            <div class="form-group">
                <label>Title</label>
                <input type="text" class="form-control" formControlName="title" maxlength="40"
                    placeholder="Enter title">
                <div class="error_box text-danger"
                    *ngIf="formsForm.controls['title'].hasError('required') && formsForm.controls['title'].touched">
                    Please enter title
                </div>
                <div class="error_box text-danger"
                    *ngIf="formsForm.controls['title'].hasError('pattern') && formsForm.controls['title'].touched">
                    Please enter valid title
                </div>
            </div>

            <div class="form-group">
                <label>Choose file</label>
                <div class="file mb-4">
                    <input type="file" accept="application/pdf, application/vnd.ms-excel" id="file"
                        aria-label="File browser example" (change)="uploadPDFForm($event)">
                    <label class="file-custom" for="file">
                        <span class="file-choose">Choose File...</span>
                        <button class="btn">Choose File</button>
                    </label>
                    <div class="error_box text-danger"
                        *ngIf="formsForm.controls['url'].hasError('required') && formsForm.controls['url'].touched">
                        Please upload form
                    </div>
                </div>
            </div>

            <div class="btn-full">
                <button type="submit" [disabled]="loading" class="btn btn-lg btn-primary">
                    <ng-container *ngIf="uploading"> Uploading... </ng-container>
                    <ng-container *ngIf="!uploading && !loading"> Submit </ng-container>
                    <ng-container *ngIf="loading"> Submitting... </ng-container>
                </button>
            </div>

        </form>
    </div>
</ng-template>

<ng-template #noCategories>
    <div *ngIf="!loading">
        <div class="card-body">
            <div class="d-flex align-items-center justify-content-center w-100 flex-column no-result">
                <i><img src="assets/img/icons/no-video_icon.svg" width="80" alt="" /></i>
                <h5 class="mt-3">No Form Added</h5>
            </div>
        </div>
    </div>
</ng-template>