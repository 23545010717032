import { INTERNAL_PATHS, SWAL_TITLES } from './../../constants';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { SwalService } from 'src/app/common/dialogue-services/swal.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';

@Component({
  selector: 'app-general-information',
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.css']
})
export class GeneralInformationComponent implements OnInit {

  infos;
  loading = false;
  selectedInfo;
  modal;

  LINKS = {
    ADD_GENERAL_INFORMATION: `/${INTERNAL_PATHS.ADMIN}/${INTERNAL_PATHS.GENERAL_INFORMATION_ADD}`
  }

  constructor(private apiService: ApiService, private swal: SwalService, private router: Router, private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.listInfo();
  }

  listInfo() {
    this.loading = true;
    this.apiService.listInfo().subscribe(res => {
      this.infos = res.data;
      this.loading = false;
    })
  }

  openInfo(id, content) {
    this.apiService.getInfoByID({ id }).subscribe(async res => {
      this.selectedInfo = res.data;
      this.modal = await this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' })
    })
  }

  async deleteInfo(id) {
    let swal_data = await this.swal.deleteSwal(SWAL_TITLES.DELETE_MESSAGE);
    if (swal_data.value) {
      this.loading = true;
      this.apiService.deleteInfo(id).subscribe(res => {
        this.listInfo();
        this.loading = false;
      })
    }
  }

  editInfo(id) {
    let url = `/${INTERNAL_PATHS.ADMIN}/${INTERNAL_PATHS.GENERAL_INFORMATION_EDIT}`
    url = url.replace(':id', id);
    this.router.navigateByUrl(url);
  }

}
