import { ApiService } from 'src/app/core/services/api.service';
import { AUTH_PATHS } from 'src/app/constants';
import { ERROR_MSG, SWAL_TITLES } from './../../constants';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'src/app/common/toastr/toastr.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { SwalService } from 'src/app/common/dialogue-services/swal.service';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  admin;

  loading = false;

  public changePasswordForm: FormGroup;
  public accountForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private common: CommonService, private swal: SwalService, private apiService: ApiService, private router: Router, private authService: AuthService, private toastr: ToastrService) {
    this.changePasswordForm = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmNewPassword: ['', Validators.required]
    })

    this.accountForm = this.formBuilder.group({
      name: [''],
      profilePicture: ['']
    })
  }

  ngOnInit(): void {
    this.getAdmin();
  }

  getAdmin() {
    this.apiService.getAdmin().subscribe(res => {
      this.admin = res.data;
      this.accountForm.controls['name'].patchValue(this.admin.name)
    })
  }

  nameChanged(event) {
    this.accountForm.controls['name'].patchValue(event.target.value);
    this.authService.editAccount({ name: this.accountForm.controls['name'].value }).subscribe(res => {
      this.common.accountUpdated();
    })
  }

  uploadNewProfile(e) {
    let file = e.target.files[0];
    this.apiService.fileUpload(file).subscribe(res => {
      this.authService.editAccount({ profilePicture: res.data.Location }).subscribe(res => {
        this.getAdmin();
        this.common.accountUpdated();
      })
    })
  }

  async changePassword() {
    if (this.changePasswordForm.valid) {
      if (this.changePasswordForm.controls['newPassword'].value !== this.changePasswordForm.controls['confirmNewPassword'].value) {
        this.toastr.showToastr({ type: 'error', message: ERROR_MSG.PASSWORD_NOT_MATCHED });
        return false;
      }
      this.loading = true;
      let swalData = await this.swal.changePasswordSwal(SWAL_TITLES.CHANGE_PASSWORD);
      if (swalData.value) {
        this.authService.changePassword(this.changePasswordForm.value).subscribe(async res => {
          this.loading = false;
          this.authService.logout().subscribe(res => {
            localStorage.clear();
            this.router.navigateByUrl(AUTH_PATHS.SIGN_IN);
          });
        }, err => {
          this.loading = false;
        })
      }
    } else {
      Object.keys(this.changePasswordForm.controls).forEach(key => {
        this.changePasswordForm.controls[key].markAsTouched({ onlySelf: true });
      })
    }
  }

}
