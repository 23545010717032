import { SwalService } from './../../common/dialogue-services/swal.service';
import { AUTH_PATHS, SIDEBAR_MENU } from './../../constants';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  menu = SIDEBAR_MENU;

  activeClassSidebar = 'side-navbar';

  constructor(private authService: AuthService, private router: Router, private swal: SwalService, private commonService: CommonService) {
    this.commonService.getFlagResonse().subscribe(res => {
      this.toggleSideClass();
    });
  }

  ngOnInit(): void {
  }

  toggleSideClass() {
    if (this.activeClassSidebar === 'side-navbar') {
      this.activeClassSidebar = 'side-navbar show';
    } else if (this.activeClassSidebar === 'side-navbar show') {
      this.activeClassSidebar = 'side-navbar'
    }
  }

  toggleSidebar() {
    $("#sidebarMenu").toggleClass("show");
    $('#content-wrapper').toggleClass('silde_lft');
  }

  async logout() {
    let swal_data = await this.swal.logoutSwal();
    if (swal_data.value) {
      this.authService.logout().subscribe(res => {
        localStorage.clear();
        this.router.navigateByUrl(AUTH_PATHS.SIGN_IN)
      })
    }
  }


}
