import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private subject = new Subject<any>();
  private accountSubject = new Subject<any>();

  constructor() { }

  toggleClass() {
    this.subject.next({ flag: true });
  }

  getFlagResonse(): Observable<any> {
    return this.subject.asObservable();
  }

  accountUpdated() {
    this.accountSubject.next();
  }

  getAccountUpdates(): Observable<any> {
    return this.accountSubject.asObservable();
  }

}
