export const AUTH_PATHS = {
    SIGN_IN: 'sign-in',
    FORGOT_PASSWORD: 'forgot-password',
    RESET_PASSWORD_BY_TOKEN: 'reset-password/:token',
    PIN_RESET_SUCCESSFULLY: 'pin-changed-successfully',
    PASSWORD_RESET_SUCCESSFULLY: 'password-changed-successfully',
    LINK_SENT_SUCCESSFULLY: 'link-sent-successfully',
    RESET_PIN_BY_TOKEN: 'reset-pin/:token'
}

export const INTERNAL_PATHS = {
    ADMIN: 'admin',
    DASHBOARD: 'dashboard',
    FORMS: 'form',
    USER_REQUEST: 'user-request',
    CATEGORIES: 'category',
    CATEGORY_WISE_EVIDENCES: 'evidence/:categoryId',
    EVIDENCE: 'evidence',
    EVIDENCE_DETAILS: 'evidence/details/:id',
    EVIDENCE_ADD: 'evidence/:categoryId/:categoryName/add',
    EVIDENCES_EDIT: 'evidence/:categoryId/:categoryName/:evidenceId/edit',
    GENERAL_INFORMATION: 'general-information',
    GENERAL_INFORMATION_ADD: 'general-information/add',
    GENERAL_INFORMATION_EDIT: 'general-information/:id/edit',
    DOMAIN: 'domain',
    ACCOUNT_SETTINGS: 'account-settings',
    DOMAIN_ADD: 'domain/add'
}

export const ADMIN_APIS = {
    // auth
    SIGN_IN: '/admin/login',
    GET_ADMIN: '/admin/get-admin',
    CHANGE_PASSWORD: '/admin/change-password',
    SIGN_OUT: '/admin/logout',
    FORGOT_PASSWORD: '/admin/forgot-password',
    RESET_PASSWORD: '/admin/reset-password',
    DASHBOARD_DATA: '/dashboard/data',
    ACCOUNT_SETTINGS: '/admin/account-edit',
    VERIFY_PASSWORD_TOKEN: '/admin/verify-password-token',

    // categories
    GET_CATEGORIES: '/category/1',
    FETCH_CATEGORY_BY_ID: '/category/fetchById',
    ADD_CATEGORY: '/category',
    DELETE_CATEGORY: '/category',
    UPDATE_CATEGORY: '/category',

    // user
    DELETE_USER: '/user',
    ADD_USER: '/addUser',
    GET_USER_LIST: '/user/list',
    GET_USER_REQUESTS: '/admin/getUserRequests',
    APPROVE_USER: '/admin/verify-user',

    // file upload
    FILE_UPLOAD: '/file-upload',

    // evidence
    GET_EVIDENCE_CATEGORY_WISE: '/category/2',
    ADD_EVIDENCE: '/evidence',
    DELETE_EVIDENCE: '/evidence',
    GET_EVIDENCE_BY_ID: '/evidence/:id',
    EDIT_EVIDENCE: '/evidence',
    GET_EVIDENCES_BY_CATEGORY: '/evidence-by-category/:categoryId',

    // forms
    FORMS: '/forms',
    DELETE_FORM: '/forms/:id',

    // domains
    DOMAIN: '/domain',

    // general information
    GENERAL_INFORMATION: '/general-information',
    LIST_GENERAL_INFORMATION: '/general-information/list',
    DELETE_GENERAL_INFORMATION: '/general-information/:id',

    // check RSA
    ENCRYPT_KEY: '/encrypt-key'
}

export const EVIDENCE_TYPES = {
    VIDEO: 0,
    PDF: 1,
    IMAGE: 2
}

export const USER_APIS = {
    RESET_PIN: '/user/reset-pin',
    VERIFY_TOKEN: '/user/verify-reset-token'
}

export const REGEX = {
    EMAIL: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/,
    // ONLY_FOUR_DIGIT: /\d{4,4}/,
    ONLY_DIGIT: /^\d+$/,
    NO_WHITESPACE_REGEX: /^[a-zA-Z]+(\s+[a-zA-Z0-9!@#$&()`.+,"-]+)*$/,
    DESCRIPTION_REGEX: /^[^\s]/
}

export const VIDEO_DIMENSIONS = {
    HEIGHT: 240,
    WIDTH: 320
}

export const TOASTR = {
    TOASTR_DELAY: 3000,
    SUCCESS: 'bg-success text-light',
    ERROR: 'bg-danger text-light',
    WARNING: 'bg-warning text-light'
}

export const TYPE_SELECT_OPTIONS = [
    {
        title: 'Video',
        value: EVIDENCE_TYPES.VIDEO
    },
    {
        title: 'PDF',
        value: EVIDENCE_TYPES.PDF
    },
    {
        title: 'Image',
        value: EVIDENCE_TYPES.IMAGE
    }
]

export const SIDEBAR_MENU = [
    {
        title: 'Dashboard',
        icon: 'assets/img/icons/dashboard_icon.svg',
        link: `/${INTERNAL_PATHS.ADMIN}/${INTERNAL_PATHS.DASHBOARD}`
    },
    {
        title: 'User Requests',
        icon: 'assets/img/icons/request_icon.svg',
        link: `/${INTERNAL_PATHS.ADMIN}/${INTERNAL_PATHS.USER_REQUEST}`
    },
    {
        title: 'Categories',
        icon: 'assets/img/icons/categories_icon.svg',
        link: `/${INTERNAL_PATHS.ADMIN}/${INTERNAL_PATHS.CATEGORIES}`
    },
    {
        title: 'Evidence Types',
        icon: 'assets/img/icons/video_icon.svg',
        link: `/${INTERNAL_PATHS.ADMIN}/${INTERNAL_PATHS.EVIDENCE}`
    },
    {
        title: 'General Information',
        icon: 'assets/img/icons/evidence_icon.svg',
        link: `/${INTERNAL_PATHS.ADMIN}/${INTERNAL_PATHS.GENERAL_INFORMATION}`
    },
    {
        title: 'Forms',
        icon: 'assets/img/icons/forms_icon.svg',
        link: `/${INTERNAL_PATHS.ADMIN}/${INTERNAL_PATHS.FORMS}`
    },
    {
        title: 'Domains',
        icon: 'assets/img/icons/domain_icon.svg',
        link: `/${INTERNAL_PATHS.ADMIN}/${INTERNAL_PATHS.DOMAIN}`
    }
]

export const ERROR_MSG = {
    PIN_NOT_MATCHED: 'New PIN should be matched with confirm PIN',
    PASSWORD_NOT_MATCHED: 'New password should be matched with confirm password',
    UNAUTHORIZED_ACCESS: 'Session has been expired, please sign in again.',
    LOGIN_TO_PROCEED: 'Please login to access this page',
    PLEASE_UPLOAD_MEDIA: 'Please upload image'
}

export const SWAL_TITLES = {
    DELETE_MESSAGE: 'Are you sure ?',
    CHANGE_PASSWORD: 'You will be logged out to sign in with new password'
}

export const SUCCESS_MSG = {
    ADMIN: {
        LOGGED_IN_SUCCESSFULLY: 'Logged in successfully',
        LOGGED_OUT_SUCCESSFULLY: 'Logged out successfully',
        LINK_SENT: 'Link has been sent succesfully',
        PASSWORD_RESET_SUCCESSFULLY: 'Password has been changed successfully !!!'
    },
    USER: {
        PIN_RESET_SUCCESSFULLY: 'Pin reset successfully'
    }
}
