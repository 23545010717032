<div class="front-forms">
    <div class="container-fluid">
        <div class="login_box">
            <div class="front-logo text-center">
                <img src="assets/img/logo.png" alt="KopShop" />
            </div>
            <div class="form-box text-center">
                <i class="text-center mb-4 d-inline-block"><img src="assets/img/icons/email_icon.svg" alt=""
                        width="80" /></i>
                <h2 class="text-center mb-2">Email Sent Successfully</h2>
                <p class="text-center">We have sent you an email to change your password. Please click on the link sent
                    on the email to change your password.</p>
                <!-- <div class="row">
                    <div class="col-12">
                        <a href="login.html" class="btn btn-md btn-primary">Login Here</a>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>