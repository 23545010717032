<div class="front-forms">
    <div class="container-fluid">
        <div class="login_box">
            <div class="front-logo text-center">
                <img src="assets/img/logo.png" alt="KopShop" />
            </div>
            <form class="form-box" [formGroup]="signInForm" (submit)="login()">
                <h2 class="text-center">Login</h2>
                <div class="row" *ngIf="!otpSent">
                    <div class="col-12">
                        <div class="form-group">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <img src="assets/img/icons/email.svg" alt="KopShop" width="22" />
                                    </span>
                                </div>
                                <input formControlName="email" type="email" class="form-control"
                                    placeholder="Enter email">
                            </div>
                            <div *ngIf="
                                signInForm.controls['email'].hasError('required') &&
                                signInForm.controls['email'].touched" class="error-box text-danger">
                                Please enter email
                            </div>
                            <div *ngIf=" 
                                signInForm.controls['email'].hasError('pattern') &&
                                signInForm.controls['email'].touched" class="error-box text-danger">
                                Please enter valid email
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="!otpSent">
                    <div class="col-12">
                        <div class="form-group">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <img src="assets/img/icons/password.svg" alt="KopShop" width="20" />
                                    </span>
                                </div>
                                <input type="password" formControlName="password" class="form-control"
                                    placeholder="Enter password">
                            </div>
                            <div *ngIf="
                    signInForm.controls['password'].hasError('required') &&
                    signInForm.controls['password'].touched" class="error-box text-danger">
                                Please enter password
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="otpSent">
                    <div class="col-12">
                        <div class="form-group">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <img src="assets/img/icons/password.svg" alt="KopShop" width="20" />
                                    </span>
                                </div>
                                <input type="text" formControlName="verificationCode" class="form-control"
                                    maxlength="6" placeholder="Enter verification code">
                            </div>
                            <div *ngIf="
                    signInForm.controls['verificationCode'].hasError('required') &&
                    signInForm.controls['verificationCode'].touched" class="error-box text-danger">
                                Please enter verification code
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="otpSent">
                    <div class="col-12">
                        <div class="form-group text-right">
                            <a href="#" (click)="backToLogin()">Back to Login</a>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="!otpSent">
                    <div class="col-12">
                        <div class="form-group text-right">
                            <a href="#" [routerLink]="LINKS.FORGOT_PASSWORD">Forgot Password ?</a>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="mt-2 mt-lg-3">
                            <button class="w-100 btn btn-lg btn-primary text-capitalize"> <span *ngIf="loading"> Logging
                                    in... </span>
                                <span *ngIf="!loading">Log in</span></button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>