import { INTERNAL_PATHS, REGEX } from 'src/app/constants';
import { SWAL_TITLES, EVIDENCE_TYPES, TYPE_SELECT_OPTIONS } from './../../constants';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/core/services/api.service';
import { SwalService } from 'src/app/common/dialogue-services/swal.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery'
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-evidence-listing',
  templateUrl: './evidence-listing.component.html',
  styleUrls: ['./evidence-listing.component.css']
})
export class EvidenceListingComponent implements OnInit {

  category;
  uploading = false;
  categoryId;
  evidences;
  selectedEvidence;
  loading = false;
  editFlag = false;
  selectedType = 5;
  typeOptions = TYPE_SELECT_OPTIONS;
  imageOrPdfFlag = false;

  openEvidenceDetails;

  public updateEvidenceForm: FormGroup;
  public addEvidenceForm: FormGroup;

  constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer, private modalService: NgbModal, private router: Router, public formBuilder: FormBuilder, private apiService: ApiService, private swal: SwalService) {
    this.updateEvidenceForm = this.formBuilder.group({
      title: ['', Validators.compose([Validators.required, Validators.pattern(REGEX.NO_WHITESPACE_REGEX)])],
      description: ['', Validators.compose([Validators.required, Validators.pattern(REGEX.DESCRIPTION_REGEX)])],
      url: ['', Validators.required],
      categoryId: ['', Validators.required],
      type: ['', Validators.required],
      id: ['', Validators.required]
    })

    this.addEvidenceForm = this.formBuilder.group({
      title: ['', Validators.compose([Validators.required, Validators.pattern(REGEX.NO_WHITESPACE_REGEX)])],
      description: ['', Validators.compose([Validators.required, Validators.pattern(REGEX.DESCRIPTION_REGEX)])],
      url: ['', Validators.required],
      categoryId: ['', Validators.required],
      type: ['', Validators.required]
    })
  }

  onSelectType(event) {
    if (event.target.value == EVIDENCE_TYPES.IMAGE || event.target.value == EVIDENCE_TYPES.PDF) {
      this.imageOrPdfFlag = true;
      this.selectedType = event.target.value;
      this.addEvidenceForm.controls['categoryId'].patchValue(this.categoryId)
    } else if (event.target.value == EVIDENCE_TYPES.VIDEO) {
      this.navigatingToAddEvidenceComponent();
      this.modalService.dismissAll()
      this.imageOrPdfFlag = false;
    }
  }

  async openEvidenceModal(evidence, content) {
    this.openEvidenceDetails = evidence;
    if (this.openEvidenceDetails.type == 1) {
      this.openEvidenceDetails.url = evidence.url
    }

    let modal_data = await this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result;
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.loading = true;
      this.categoryId = params.categoryId;
      this.getEvidencesByCategory(this.categoryId)
    })
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  getEvidencesByCategory(id) {
    this.apiService.getEvidencesByCategory(id).subscribe(async res => {
      this.loading = false;
      this.category = res.category;
      this.evidences = res.data;
    })
  }

  async deleteEvidence(id) {
    let swal_data = await this.swal.deleteSwal(SWAL_TITLES.DELETE_MESSAGE);
    if (swal_data.value) {
      this.apiService.deleteEvidence(id).subscribe(res => {
        this.getEvidencesByCategory(this.categoryId);
      })
    }
  }

  addEvidence() {
    if (this.addEvidenceForm.valid) {
      this.loading = true;
      this.apiService.addEvidence(this.addEvidenceForm.value).subscribe(res => {
        this.loading = false
        this.modalService.dismissAll();
        this.getEvidencesByCategory(this.categoryId);
        this.addEvidenceForm.reset();
      })
    } else {
      Object.keys(this.addEvidenceForm.controls).forEach(key => {
        this.addEvidenceForm.controls[key].markAsTouched({ onlySelf: true })
      })
    }
  }

  openEvidence(id, type) {
    console.log(id, type)
    if (type == EVIDENCE_TYPES.VIDEO) {
      let url = INTERNAL_PATHS.EVIDENCE_DETAILS;
      url = url.replace(":id", id);
      this.router.navigateByUrl(`${INTERNAL_PATHS.ADMIN}/${url}`);
    }
  }

  updateEvidenceData() {
    if (this.updateEvidenceForm.valid) {
      this.apiService.updateEvidence(this.updateEvidenceForm.value).subscribe(res => {
        this.getEvidencesByCategory(this.categoryId);
        this.modalService.dismissAll();
        this.editFlag = false;
      })
    } else {
      Object.keys(this.updateEvidenceForm.controls).forEach(key => {
        this.updateEvidenceForm.controls[key].markAsTouched({ onlySelf: true })
      })
    }
  }

  async editEvidence(evidenceId, type, content: any = {}) {
    if (type == EVIDENCE_TYPES.VIDEO) {
      let url = INTERNAL_PATHS.EVIDENCES_EDIT;
      url = url.replace(":categoryId", this.categoryId);
      url = url.replace(":categoryName", this.category.name);
      url = url.replace(":evidenceId", evidenceId);
      url = `${INTERNAL_PATHS.ADMIN}/${url}`
      this.router.navigateByUrl(url);
    } else if (type == EVIDENCE_TYPES.IMAGE || type == EVIDENCE_TYPES.PDF) {
      this.editFlag = true;
      this.apiService.getEvidence(evidenceId).subscribe(async res => {
        this.selectedEvidence = res.data;
        this.updateEvidenceForm.controls['title'].patchValue(this.selectedEvidence.title);
        this.updateEvidenceForm.controls['description'].patchValue(this.selectedEvidence.description);
        this.updateEvidenceForm.controls['url'].patchValue(this.selectedEvidence.url);
        this.updateEvidenceForm.controls['type'].patchValue(this.selectedEvidence.type);
        this.updateEvidenceForm.controls['categoryId'].patchValue(this.selectedEvidence.categoryId);
        this.updateEvidenceForm.controls['id'].patchValue(this.selectedEvidence._id);
        let modal_data = await this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result;
      })
    }
  }

  async openNewEvidenceType(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(result => {
    }, (reason) => {
      this.imageOrPdfFlag = false;
    });
  }

  navigatingToAddEvidenceComponent() {
    let url = INTERNAL_PATHS.EVIDENCE_ADD;
    url = url.replace(":categoryId", this.categoryId);
    url = url.replace(":categoryName", this.category.name);
    url = `${INTERNAL_PATHS.ADMIN}/${url}`
    this.router.navigateByUrl(url);
  }

  async upload(event) {
    if (this.editFlag) {
      let file = event.target.files[0];
      if (file.type.includes('image')) {
        this.updateEvidenceForm.controls['type'].patchValue(EVIDENCE_TYPES.IMAGE)
      } else if (file.type.includes('pdf')) {
        this.updateEvidenceForm.controls['type'].patchValue(EVIDENCE_TYPES.PDF)
      }
      this.uploading = true;
      this.apiService.fileUpload(file).subscribe(res => {
        this.uploading = false;
        this.updateEvidenceForm.controls['url'].patchValue(res.data.Location);
      })
      $('.file-custom').text(file.name);
    } else {
      let file = event.target.files[0];
      if (file.type.includes('image')) {
        this.addEvidenceForm.controls['type'].patchValue(EVIDENCE_TYPES.IMAGE)
      } else if (file.type.includes('pdf')) {
        this.addEvidenceForm.controls['type'].patchValue(EVIDENCE_TYPES.PDF)
      }
      this.uploading = true;
      this.apiService.fileUpload(file).subscribe(res => {
        this.uploading = false;
        this.addEvidenceForm.controls['url'].patchValue(res.data.Location);
      })
      $('.file-custom').text(file.name);
    }
  }

  /**
   * extracting frame from video
   */

}
