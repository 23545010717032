import { REGEX } from './../../constants';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/core/services/api.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.css']
})
export class FormsComponent implements OnInit {

  public formsForm: FormGroup;

  forms;
  uploading;
  loading;
  editFlag = false;
  selectedForm;

  constructor(private modalService: NgbModal, private router: Router, private formBuilder: FormBuilder, private apiService: ApiService) {
    this.formsForm = this.formBuilder.group({
      title: ['', Validators.compose([Validators.required, Validators.pattern(REGEX.NO_WHITESPACE_REGEX)])],
      url: ['', Validators.required]
    })
  }

  ngOnInit(): void {
    this.getForms();
  }

  addForm() {
    if (this.formsForm.valid) {
      this.loading = true;
      this.apiService.addForm(this.formsForm.value).subscribe(res => {
        this.loading = false;
        this.getForms();
        this.modalService.dismissAll();
        this.formsForm.reset();
      }, err => {
        this.loading = false;
      })
    } else {
      Object.keys(this.formsForm.controls).forEach(key => {
        this.formsForm.controls[key].markAsTouched({ onlySelf: true })
      })
    }
  }

  getForms() {
    this.loading = true;
    let query = {
      sortKey: 'createdAt',
      sortDirection: -1
    }
    this.apiService.getForms(query).subscribe(res => {
      this.forms = res.data;
      this.loading = false;
    }, err => {
      this.loading = false;
    })
  }

  updateForm() {
    if (this.formsForm.valid) {
      this.loading = true;
      const obj = { ...this.formsForm.value, ...{ id: this.selectedForm._id } };
      this.apiService.updateForm(obj).subscribe(res => {
        this.loading = false;
        this.getForms();
        this.modalService.dismissAll();
      }, err => {
        this.loading = false;
      })
    } else {
      Object.keys(this.formsForm.controls).forEach(key => {
        this.formsForm.controls[key].markAsTouched({ onlySelf: true })
      })
    }
  }

  uploadPDFForm(event) {
    let file = event.target.files[0];
    $('.file-custom').text(file.name);
    this.uploading = true;
    this.apiService.fileUpload(file).subscribe(res => {
      this.uploading = false;
      this.formsForm.controls['url'].patchValue(res.data.Location)
    }, err => {
      this.uploading = false;
    })
  }

  deleteForm(id) {
    this.loading = true;
    this.apiService.deleteForm(id).subscribe(res => {
      this.getForms();
      this.loading = false
    }, err => {
      this.loading = false
    })
  }

  async hideModal() {
    await this.modalService.dismissAll();
  }

  submitOperation() {
    if (!this.editFlag) {
      this.addForm()
    } else {
      this.updateForm()
    }
  }

  async openModal(content, editFlag, item: any = {}) {
    if (!editFlag) {
      this.formsForm.reset();
    } else {
      this.editFlag = true
      this.selectedForm = item;
      this.formsForm.controls['title'].patchValue(item.title);
      this.formsForm.controls['url'].patchValue(item.url);
    }
    let modal_data = await this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result;
    $('.file-custom').text(item.url);
  }

}
