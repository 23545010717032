import { REGEX, SWAL_TITLES } from './../../constants';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwalService } from 'src/app/common/dialogue-services/swal.service';
import { ToastrService } from 'src/app/common/toastr/toastr.service';
import { ApiService } from 'src/app/core/services/api.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  @Output() userListUpdated = new EventEmitter<any>();

  limit = 10;
  skip = 0;
  loading = false;
  collectionSize;
  page = 1;
  pageSize = 10;
  default_sorting = {
    sortKey: 'createdAt',
    sortDirection: -1
  }
  active_class;

  default_pagination = {
    limit: 10,
    skip: 0
  }

  public userForm: FormGroup;

  users;

  constructor(private apiService: ApiService, private modalService: NgbModal, private toastr: ToastrService, private swal: SwalService, private formBuilder: FormBuilder) {
    this.userForm = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required, Validators.pattern(REGEX.EMAIL)])],
      pin: ['', Validators.compose([Validators.required, Validators.minLength(4), Validators.pattern(REGEX.ONLY_DIGIT)])],
      confirmPin: ['', Validators.compose([Validators.required, Validators.minLength(4), Validators.pattern(REGEX.ONLY_DIGIT)])],
      fsi: ['', Validators.required]
    })
  }

  ngOnInit(): void {
    this.intialHits();
  }

  async intialHits() {
    this.loading = true;
    await this.getUserList({ ...this.default_pagination, ...this.default_sorting });
    this.loading = false;
  }

  sorting(sortKey, sortDirection) {
    this.default_sorting['sortKey'] = sortKey;
    this.default_sorting['sortDirection'] = sortDirection;
    this.skip = (this.page - 1) * this.pageSize;
    this.getUserList({ ...{ limit: this.pageSize, skip: this.skip }, ...this.default_sorting });
    this.active_class = `${sortKey}${sortDirection}`
  }

  async getUserList(pagination) {
    this.loading = true;
    let temp = await this.apiService.getUserList(pagination).toPromise();
    this.users = temp.data;
    this.collectionSize = temp.totalCount;
    this.loading = false;
  }


  addNewUser() {
    if (this.userForm.valid) {
      if (this.userForm.controls['pin'].value === this.userForm.controls['confirmPin'].value) {
        this.loading = true;
        this.apiService.addUser(this.userForm.value).subscribe(res => {
          this.getUserList({ ...this.default_pagination, ...this.default_sorting });
          this.modalService.dismissAll();
          this.userListUpdated.emit();
          this.loading = false;
        }, err => {
          this.loading = false;
        })
      } else {
        this.toastr.showToastr({ type: 'error', message: 'PINS not matched' })
      }
    } else {
      Object.keys(this.userForm.controls).forEach(key => {
        this.userForm.controls[key].markAsTouched({ onlySelf: true })
      })
    }
  }

  open(content) {
    this.userForm.reset();
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`
    });
  }

  async deleteUser(id) {
    let swal_data = await this.swal.deleteSwal(SWAL_TITLES.DELETE_MESSAGE);
    if (swal_data.value) {
      this.loading = true;
      this.apiService.deleteUser(id).subscribe(res => {
        this.getUserList({ ...this.default_pagination, ...this.default_sorting });
        this.userListUpdated.emit();
        this.loading = false;
      })
    }
  }

  pageChangeEvent(e) {
    this.page = e;
    this.skip = (this.page - 1) * this.pageSize;
    this.loading = true;
    this.getUserList({
      ...this.default_sorting,
      ...{
        skip: this.skip,
        limit: this.pageSize
      }
    })
    this.loading = false;
  }

}
