import { ADMIN_APIS } from 'src/app/constants';
import { USER_APIS } from './../../constants';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private httpService: HttpService) { }

  login(obj) {
    return this.httpService.postData(ADMIN_APIS.SIGN_IN, obj)
  }

  logout() {
    return this.httpService.getData(ADMIN_APIS.SIGN_OUT)
  }

  getLoggedInUser() {
    return this.httpService.getData(ADMIN_APIS.GET_ADMIN);
  }

  forgotPassword(obj) {
    return this.httpService.postData(ADMIN_APIS.FORGOT_PASSWORD, obj);
  }

  editAccount(data) {
    return this.httpService.putData(ADMIN_APIS.ACCOUNT_SETTINGS, data);
  }

  resetPin(obj) {
    return this.httpService.postData(USER_APIS.RESET_PIN, obj);
  }

  verifyResetPinToken(obj) {
    return this.httpService.postData(USER_APIS.VERIFY_TOKEN, obj);
  }

  verifyResetPasswordToken(obj) {
    return this.httpService.postData(ADMIN_APIS.VERIFY_PASSWORD_TOKEN, obj);
  }

  resetPasswordAdmin(obj) {
    return this.httpService.postData(ADMIN_APIS.RESET_PASSWORD, obj);
  }

  changePassword(data) {
    return this.httpService.putData(ADMIN_APIS.CHANGE_PASSWORD, data)
  }
}
