import { NgxSpinnerService } from 'ngx-spinner';
import { ERROR_MSG, AUTH_PATHS, USER_APIS } from './../../constants';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpResponse, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from '../../common/toastr/toastr.service';
import { ADMIN_APIS } from 'src/app/constants';
import { AuthService } from './auth.service';
import { RsaService } from './rsa.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private rsa: RsaService,
    private authService: AuthService,
    private loader: NgxSpinnerService
  ) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Clone the request to add the new header.
    const token = localStorage.getItem("accessToken");
    this.loader.show();
    if (token) {
      req = req.clone({
        headers: req.headers.set("authorization", token),
      });
    }
    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        /**
         * TODO: intercepting response
         */
        if (event instanceof HttpResponse) {
          // if(!event.url.includes(ADMIN_APIS.GET_ADMIN)) {
            event = event.clone({ body: this.modifyBody(event.body) });
          // }
        }
        if (
          !event.url.includes(ADMIN_APIS.DASHBOARD_DATA) &&
          !event.url.includes(ADMIN_APIS.FILE_UPLOAD) &&
          !event.url.includes(ADMIN_APIS.FORGOT_PASSWORD) &&
          !event.url.includes(ADMIN_APIS.GET_USER_REQUESTS) &&
          !event.url.includes(ADMIN_APIS.GET_ADMIN) &&
          !event.url.includes('evidence-by-category') &&
          !event.url.includes(ADMIN_APIS.GET_CATEGORIES) &&
          !event.url.includes(ADMIN_APIS.ACCOUNT_SETTINGS) &&
          !event.url.includes(USER_APIS.VERIFY_TOKEN) &&
          !event.url.includes(ADMIN_APIS.VERIFY_PASSWORD_TOKEN) &&
          !event.url.includes(ADMIN_APIS.GENERAL_INFORMATION) &&
          !event.url.includes(ADMIN_APIS.LIST_GENERAL_INFORMATION) &&
          !event.url.includes(ADMIN_APIS.GET_EVIDENCE_CATEGORY_WISE) &&
          !event.url.includes(ADMIN_APIS.GET_USER_LIST)) {
          const message = event.body.msg;
          this.toastr.showToastr({ type: 'success', message });
        }
        this.loader.hide();
      }
    },
      async (err: any) => {
        const status = err.error.statusCode;
        const message = err.error.msg;
        if (status === 401) {
          localStorage.clear();
          this.toastr.showToastr({ type: 'error', message: ERROR_MSG.UNAUTHORIZED_ACCESS });
          this.router.navigateByUrl(`/${AUTH_PATHS.SIGN_IN}`)
        } else {
          if (
            !err.url.includes(USER_APIS.VERIFY_TOKEN) &&
            !err.url.includes(ADMIN_APIS.VERIFY_PASSWORD_TOKEN)
          ) {
            this.toastr.showToastr({ type: 'error', message });
          }
        }
        this.loader.hide();
      }));
  }

  modifyBody(data) {
    let dt = {...data.data}
    try{
      data.data = this.rsa.decryptData(data.data);
      if (data.category) {
        data.category = this.rsa.decryptData(data.category);
      }
    } catch {
      return {...data, data: {...dt}};
    }
    return data;
  }
}