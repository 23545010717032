import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { REGEX, AUTH_PATHS, INTERNAL_PATHS } from '../../constants';
import { ToastrService } from '../../common/toastr/toastr.service';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {

  loading = false;
  otpSent = false;

  LINKS = {
    FORGOT_PASSWORD: `/${AUTH_PATHS.FORGOT_PASSWORD}`
  }

  public signInForm: FormGroup;

  constructor(private fb: FormBuilder, private router: Router, private toastrService: ToastrService, private authService: AuthService) {
    this.signInForm = this.fb.group({
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern(REGEX.EMAIL)
      ])],
      password: ['', Validators.compose([
        Validators.required
      ])],
      verificationCode: ['']
    })
  }

  ngOnInit(): void {
  }

  login() {
    if (this.signInForm.valid) {
      this.loading = true;
      if(this.otpSent) {
        this.authService.login(this.signInForm.value).subscribe(res => {
          this.loading = false;
          localStorage.setItem('accessToken', res.token)
          this.router.navigateByUrl(`${INTERNAL_PATHS.ADMIN}`)
        }, err => {
          this.loading = false;
        })
      } else {
        let loginData = {
          email: this.signInForm.value.email,
          password: this.signInForm.value.password
        }
        this.authService.login(loginData).subscribe(res => {
          this.loading = false;
          this.otpSent = true;
          this.signInForm.controls['verificationCode'].setValidators(Validators.compose([Validators.required]))
          this.signInForm.controls['verificationCode'].updateValueAndValidity();

        }, err => {
          this.loading = false;
        })
      }
      
    } else {
      Object.keys(this.signInForm.controls).forEach(key => {
        this.signInForm.controls[key].markAsTouched({ onlySelf: true });
      });
    }
  }

  backToLogin() {
    this.signInForm.reset();
    this.otpSent = false;
  }

}
