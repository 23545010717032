<div class="front-forms pwd_updated">
    <div class="container-fluid">
        <div class="login_box">
            <div class="front-logo text-center"><img src="assets/img/logo.png" alt="KopShop" /></div>
            <div class="form-box text-center">
                <i class="text-center mb-4 d-inline-block"><img src="assets/img/icons/checked-icon.svg" alt=""
                        width="80" /></i>
                <h2>PIN Updated</h2>
                <p class="mb-0">Your PIN has been changed successfully. Use your new PIN to login</p>
                <!-- <div class="row pt-3">
                    <div class="col-12">
                        <a href="login.html" class="link">Login Here</a>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>