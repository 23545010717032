<div class="card user_table box_vh">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3 class="">Users</h3>
        <button class="btn btn-md btn-primary" (click)="open(addUser)" data-toggle="modal">Add New</button>
    </div>
    <div class="card-body" *ngIf="users && users?.length > 0; else noRecords">
        <div class="table-responsive">
            <table class="table table-hover" id="table_data" style="width:100%">
                <thead>
                    <tr>
                        <th>Name
                            <div class="sorting">
                                <i (click)="sorting('name', 1)" [class]="active_class === 'name1' ? 'active' : ''"><img
                                        src="/assets/img/icons/arw_up.png" alt="sort" /></i>
                                <i (click)="sorting('name', -1)"
                                    [class]="active_class === 'name-1' ? 'active' : ''"><img
                                        src="/assets/img/icons/arw_down.png" alt="sort" /></i>
                            </div>
                        </th>
                        <th>Email
                            <div class="sorting">
                                <i (click)="sorting('email', 1)"
                                    [class]="active_class === 'email1' ? 'active' : ''"><img
                                        src="/assets/img/icons/arw_up.png" alt="sort" /></i>
                                <i (click)="sorting('email', -1)"
                                    [class]="active_class === 'email-1' ? 'active' : ''"><img
                                        src="/assets/img/icons/arw_down.png" alt="sort" /></i>
                            </div>
                        </th>
                        <th>ID No
                            <div class="sorting">
                                <i (click)="sorting('fsi', 1)" [class]="active_class === 'fsi1' ? 'active' : ''"><img
                                        src="/assets/img/icons/arw_up.png" alt="sort" /></i>
                                <i (click)="sorting('fsi', -1)" [class]="active_class === 'fsi-1' ? 'active' : ''"><img
                                        src="/assets/img/icons/arw_down.png" alt="sort" /></i>
                            </div>
                        </th>
                        <th>Approval </th>
                        <th>Status </th>
                        <th class="text-right">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of users">
                        <tr>
                            <td class="user_info">
                                <i class="icon">
                                    <img [src]="item.profilePicture || '/assets/img/user_logo.png'" [alt]="item.name" />
                                </i>
                                {{item.name}}
                            </td>
                            <td>{{item.email}}</td>
                            <td>{{item.fsi}}</td>
                            <td>
                                <span *ngIf="item.isVerified">Confirmed</span>
                                <span *ngIf="!item.isVerified">Pending</span>
                            </td>
                            <td>
                                <span *ngIf="item.isArchived">Archived</span>
                                <span *ngIf="!item.isArchived">Active</span>
                            </td>
                            <td class="text-right">
                                <div class="action_btn">
                                    <!-- <i class="edit_icon" (click)="editUser(item)"><img
                                            src="/assets/img/icons/edit_icon.svg" alt="" /></i> -->
                                    <i class="del_icon" (click)="deleteUser(item._id)"><img
                                            src="/assets/img/icons/delete_icon.svg" alt="" /></i>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
        <div class="pagination">
            <app-pagination [collectionSize]="collectionSize" [page]="page" [pageSize]="pageSize"
                (pageChangeEvent)="pageChangeEvent($event)"></app-pagination>
        </div>
    </div>
</div>

<ng-template #noRecords>
    <div *ngIf="!loading" class="no_user_req">
        <img src="/assets/img/icons/all_users_icon.svg">
        <p> No user added yet !!! </p>
    </div>
</ng-template>

<ng-template #addUser let-modal>
    <div class="modal-header text-center">
        <button type="button" (click)="modal.dismiss('Cross click')" class="close"><img
                src="assets/img/icons/close_icon.svg" alt="" /></button>
        <h3 class="modal-title text-center">Add New User</h3>
    </div>
    <div class="modal-body">
        <form [formGroup]="userForm" (submit)="addNewUser()">
            <div class="form-group">
                <label>Name</label>
                <input type="text" class="form-control" formControlName="name" maxlength="30" placeholder="Enter name">
                <div class="error_box text-danger" *ngIf="
                    userForm.controls['name'].hasError('required') && userForm.controls['name'].touched">
                    Please enter name
                </div>
                <div class="error_box text-danger" *ngIf="
                userForm.controls['name'].hasError('pattern') && userForm.controls['name'].touched">
                    Please enter valid name
                </div>
            </div>
            <div class="form-group">
                <label>E-mail</label>
                <input type="text" class="form-control" formControlName="email" maxlength="50"
                    placeholder="Enter email">
                <div class="error_box text-danger" *ngIf="
                    userForm.controls['email'].hasError('required') && userForm.controls['email'].touched">
                    Please enter email
                </div>
                <div class="error_box text-danger" *ngIf="
                userForm.controls['email'].hasError('pattern') && userForm.controls['email'].touched">
                    Please enter valid email
                </div>
            </div>
            <div class="form-group">
                <label>ID Number</label>
                <input type="text" class="form-control" maxlength="10" formControlName="fsi"
                    placeholder="Enter id number">
                <div class="error_box text-danger" *ngIf="
                    userForm.controls['fsi'].hasError('required') && userForm.controls['fsi'].touched">
                    Please enter ID number
                </div>
                <!-- <div class="error_box text-danger" *ngIf="
                userForm.controls['fsi'].hasError('pattern') && userForm.controls['fsi'].touched">
                    Please enter valid fsi
                </div> -->
            </div>
            <div class="form-group">
                <label>PIN</label>
                <input type="text" class="form-control" formControlName="pin" maxlength="4" placeholder="Enter PIN">
                <div class="error_box text-danger" *ngIf="
                    userForm.controls['pin'].hasError('required') && userForm.controls['pin'].touched">
                    Please enter pin
                </div>
                <div class="error_box text-danger" *ngIf="
                userForm.controls['pin'].hasError('pattern') && userForm.controls['pin'].touched">
                    Please enter valid pin
                </div>
            </div>
            <div class="form-group">
                <label>Confirm PIN</label>
                <input type="text" class="form-control" maxlength="4" formControlName="confirmPin"
                    placeholder="Confirm PIN">
                <div class="error_box text-danger" *ngIf="
                    userForm.controls['confirmPin'].hasError('required') && userForm.controls['confirmPin'].touched">
                    Please re-enter pin
                </div>
                <div class="error_box text-danger" *ngIf="
                userForm.controls['confirmPin'].hasError('pattern') && userForm.controls['confirmPin'].touched">
                    Please enter valid pin
                </div>
            </div>

            <!-- <div class="form-group"> -->
            <!-- <label class="form-check-label"> -->
            <!-- <input type="checkbox" class="form-control"> -->
            <!-- I agree not to share the information in this app with anyone outside of my organisation. Tick in
                    order to progress to the main menu. -->
            <!-- </label> -->
            <!-- </div> -->

            <div class="btn-full">
                <button type="submit" [disabled]="loading" class="btn btn-lg btn-primary">
                    <ng-container *ngIf="!loading"> Submit </ng-container>
                    <ng-container *ngIf="loading"> Submitting... </ng-container>
                </button>
                <!-- <button type="button" [disabled]="true" *ngIf="loading" class="btn btn-lg btn-primary">
                    Submitting...
                </button> -->
            </div>
        </form>
    </div>
</ng-template>