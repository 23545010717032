<div class="card user_table default_block box_vh">
    <div
        class="card-header d-flex justify-content-start justify-content-md-between align-items-start align-items-md-center">
        <div class="breadcrumbs d-flex">
            <h3 class="">Item Type</h3>
            <span><i><img src="assets/img/icons/right_arrow_icon.svg" alt="" /></i> {{category?.name}}</span>
        </div>
        <button class="btn btn-md btn-primary" (click)="openNewEvidenceType(addNewEvidence)">Add New Item</button>
    </div>

    <div class="card-body" *ngIf="evidences && evidences.length !== 0;else noEvidences">
        <div class="row video_icon">
            <ng-container *ngFor="let item of evidences">
                <div class="col-lg-4 col-xl-3 col-md-6 col-sm-12">
                    <div class="cat_box text-center">
                        <!-- Image/PDF/Video Evidence Listing -->
                        <span *ngIf="item.type == 0">
                            <video width="320" height="240" [poster]="item.thumbnail" controls muted>
                                <source [src]="item.url" [type]="item.videoType"></video>
                        </span>
                        <span *ngIf="item.type == 2">
                            <img [src]="item.url" [alt]="item.title">
                        </span>
                        <span *ngIf="item.type == 1">
                            <img class="pdf_icon" src="assets/img/pdf_logo.png">
                        </span>
                        <!-- Ends here -->

                        <div class=" d-flex align-items-center justify-content-between head_drop">
                            <div class="col-10">
                                <a *ngIf="item.type == 0" href="javascript:void(0)"
                                    (click)="openEvidence(item._id, item.type)">
                                    <h6>{{item.title}}</h6>
                                </a>
                                <a *ngIf="item.type != 0" href="javascript:void(0)">
                                    <a (click)="openEvidenceModal(item, evidenceDetails)">
                                        <h6>{{item.title}}</h6>
                                    </a>
                                </a>
                            </div>
                            <div class="d-flex dots_drop col-2">
                                <a href="#" class="actions" id="drop2" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    <img src="assets/img/icons/dots_icon.svg" class="img-open" alt="" />
                                    <img src="assets/img/icons/close_icon.svg" class="img-close" alt="" />
                                </a>
                                <div class="dropdown-menu shadow" aria-labelledby="drop2">
                                    <i (click)="editEvidence(item._id, item.type, updateEvidence)"><img
                                            src="assets/img/icons/edit_icon.svg" alt="logout" /></i>
                                    <i (click)="deleteEvidence(item._id)"><img src="assets/img/icons/delete_icon.svg"
                                            alt="logout" /></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #noEvidences>
    <div *ngIf=!loading>
        <div class="card-body">
            <div class="d-flex align-items-center justify-content-center w-100 flex-column no-result">
                <i><img src="assets/img/icons/no-video_icon.svg" width="80" alt="" /></i>
                <h5 class="mt-3">No Item Added</h5>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #updateEvidence let-modal>
    <div class="modal-header text-center">
        <button type="button" (click)="modal.dismiss()" class="close"><img src="assets/img/icons/close_icon.svg"
                alt="" /></button>
        <h3 class="modal-title text-center">Update Evidence</h3>
    </div>
    <div class="modal-body">
        <form [formGroup]="updateEvidenceForm" (submit)="updateEvidenceData()">
            <ng-container *ngIf="selectedEvidence.type == 2">
                <div class="form-group selected_image">
                    <img [src]="selectedEvidence.url" />
                </div>

                <div class="form-group">
                    <label>Title</label>
                    <input type="text" class="form-control" formControlName="title" maxlength="20"
                        placeholder="Enter title">
                    <div class="error_box text-danger"
                        *ngIf="updateEvidenceForm.controls['title'].hasError('required') && updateEvidenceForm.controls['title'].touched">
                        Please enter title
                    </div>
                    <div class="error_box text-danger"
                        *ngIf="updateEvidenceForm.controls['title'].hasError('pattern') && updateEvidenceForm.controls['title'].touched">
                        Please enter valid title
                    </div>
                </div>

                <div class="form-group">
                    <label>Description</label>
                    <textarea class="form-control" formControlName="description"
                        placeholder="Enter description"></textarea>
                    <div class="error_box text-danger"
                        *ngIf="updateEvidenceForm.controls['description'].hasError('required') && updateEvidenceForm.controls['description'].touched">
                        Please enter description
                    </div>
                    <div class="error_box text-danger"
                        *ngIf="updateEvidenceForm.controls['description'].hasError('pattern') && updateEvidenceForm.controls['description'].touched">
                        Please enter valid description
                    </div>
                </div>

                <div class="form-group">
                    <label>Change File</label>
                    <div class="file mb-4">
                        <input type="file" accept="image/*" id="file" aria-label="File browser example"
                            (change)="upload($event)">
                        <label class="file-custom" for="file">
                            <span class="file-choose">Choose File...</span>
                            <button class="btn">Choose File</button>
                        </label>
                        <div class="error_box text-danger"
                            *ngIf="updateEvidenceForm.controls['url'].hasError('required') && updateEvidenceForm.controls['url'].touched">
                            Please upload image
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="selectedEvidence.type == 1">
                <div class="form-group">
                    <a [href]="selectedEvidence.url" target="_blank">View uploaded PDF</a>
                </div>

                <div class="form-group">
                    <label>Title</label>
                    <input type="text" class="form-control" formControlName="title" maxlength="20"
                        placeholder="Enter title">
                    <div class="error_box text-danger"
                        *ngIf="updateEvidenceForm.controls['title'].hasError('required') && updateEvidenceForm.controls['title'].touched">
                        Please enter title
                    </div>
                    <div class="error_box text-danger"
                        *ngIf="updateEvidenceForm.controls['title'].hasError('pattern') && updateEvidenceForm.controls['title'].touched">
                        Please enter valid title
                    </div>
                </div>

                <div class="form-group">
                    <label>Description</label>
                    <textarea class="form-control" formControlName="description"
                        placeholder="Enter description"></textarea>
                    <div class="error_box text-danger"
                        *ngIf="updateEvidenceForm.controls['description'].hasError('required') && updateEvidenceForm.controls['description'].touched">
                        Please enter description
                    </div>
                    <div class="error_box text-danger"
                        *ngIf="updateEvidenceForm.controls['description'].hasError('pattern') && updateEvidenceForm.controls['description'].touched">
                        Please enter valid description
                    </div>
                </div>

                <div class="form-group">
                    <label>Change File</label>
                    <div class="file mb-4">
                        <input type="file" accept="application/pdf" id="file" aria-label="File browser example"
                            (change)="upload($event)">
                        <label class="file-custom" for="file">
                            <span class="file-choose">Choose File...</span>
                            <button class="btn">Choose File</button>
                        </label>
                        <div class="error_box text-danger"
                            *ngIf="updateEvidenceForm.controls['url'].hasError('required') && updateEvidenceForm.controls['url'].touched">
                            Please upload PDF
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="btn-full">
                <button type="submit" [disabled]="loading" class="btn btn-lg btn-primary">
                    <ng-container *ngIf="uploading"> Uploading... </ng-container>
                    <ng-container *ngIf="!uploading && !loading"> Submit </ng-container>
                    <ng-container *ngIf="loading"> Submit </ng-container>
                </button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #addNewEvidence let-modal>
    <ng-container *ngIf="!imageOrPdfFlag">
        <div class="modal-header text-center">
            <button type="button" (click)="modal.dismiss('Cross click')" class="close"><img
                    src="assets/img/icons/close_icon.svg" alt="" /></button>
            <h3 class="modal-title text-center">Choose Item Type</h3>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <label>Choose Item Type</label>
                <select (change)="onSelectType($event)" class="form-control">
                    <option value="5">Choose one...</option>
                    <option *ngFor="let item of typeOptions" [value]="item.value"> {{item.title}} </option>
                </select>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="imageOrPdfFlag">
        <div class="modal-header text-center">
            <button type="button" (click)="modal.dismiss('Cross click')" class="close"><img
                    src="assets/img/icons/close_icon.svg" alt="" /></button>
            <h3 class="modal-title text-center">Enter New Item</h3>
        </div>

        <div class="modal-body">
            <form [formGroup]="addEvidenceForm" (submit)="addEvidence()">
                <ng-container *ngIf="selectedType == 2">
                    <div class="form-group">
                        <label>Title</label>
                        <input type="text" class="form-control" formControlName="title" maxlength="20"
                            placeholder="Enter title">
                        <div class="error_box text-danger"
                            *ngIf="addEvidenceForm.controls['title'].hasError('required') && addEvidenceForm.controls['title'].touched">
                            Please enter title
                        </div>
                        <div class="error_box text-danger"
                            *ngIf="addEvidenceForm.controls['title'].hasError('pattern') && addEvidenceForm.controls['title'].touched">
                            Please enter valid title
                        </div>
                    </div>

                    <div class="form-group">
                        <label>Description</label>
                        <textarea class="form-control" formControlName="description"
                            placeholder="Enter description"></textarea>
                        <div class="error_box text-danger"
                            *ngIf="addEvidenceForm.controls['description'].hasError('required') && addEvidenceForm.controls['description'].touched">
                            Please enter description
                        </div>
                        <div class="error_box text-danger"
                            *ngIf="addEvidenceForm.controls['description'].hasError('pattern') && addEvidenceForm.controls['description'].touched">
                            Please enter valid description
                        </div>
                    </div>

                    <div class="form-group">
                        <label>Change File</label>
                        <div class="file mb-4">
                            <input type="file" accept="image/*" id="file" aria-label="File browser example"
                                (change)="upload($event)">
                            <label class="file-custom" for="file">
                                <span class="file-choose">Choose File...</span>
                                <button class="btn">Choose File</button>
                            </label>
                            <div class="error_box text-danger"
                                *ngIf="addEvidenceForm.controls['url'].hasError('required') && addEvidenceForm.controls['url'].touched">
                                Please upload image
                            </div>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="selectedType == 1">
                    <div class="form-group">
                        <label>Title</label>
                        <input type="text" class="form-control" formControlName="title" maxlength="20"
                            placeholder="Enter title">
                        <div class="error_box text-danger"
                            *ngIf="addEvidenceForm.controls['title'].hasError('required') && addEvidenceForm.controls['title'].touched">
                            Please enter title
                        </div>
                        <div class="error_box text-danger"
                            *ngIf="addEvidenceForm.controls['title'].hasError('pattern') && addEvidenceForm.controls['title'].touched">
                            Please enter valid title
                        </div>
                    </div>

                    <div class="form-group">
                        <label>Description</label>
                        <textarea class="form-control" formControlName="description"
                            placeholder="Enter description"></textarea>
                        <div class="error_box text-danger"
                            *ngIf="addEvidenceForm.controls['description'].hasError('required') && addEvidenceForm.controls['description'].touched">
                            Please enter description
                        </div>
                        <div class="error_box text-danger"
                            *ngIf="addEvidenceForm.controls['description'].hasError('pattern') && addEvidenceForm.controls['description'].touched">
                            Please enter valid description
                        </div>
                    </div>

                    <div class="form-group">
                        <label>Change File</label>
                        <div class="file mb-4">
                            <input type="file" accept="application/pdf" id="file" aria-label="File browser example"
                                (change)="upload($event)">
                            <label class="file-custom" for="file">
                                <span class="file-choose">Choose File...</span>
                                <button class="btn">Choose File</button>
                            </label>
                            <div class="error_box text-danger"
                                *ngIf="addEvidenceForm.controls['url'].hasError('required') && addEvidenceForm.controls['url'].touched">
                                Please upload PDF
                            </div>
                        </div>
                    </div>
                </ng-container>

                <div class="btn-full">
                    <button type="submit" [disabled]="loading" class="btn btn-lg btn-primary">
                        <ng-container *ngIf="uploading"> Uploading... </ng-container>
                        <ng-container *ngIf="!uploading && !loading"> Submit </ng-container>
                        <ng-container *ngIf="loading"> Submitting... </ng-container>
                    </button>
                </div>
            </form>
        </div>
    </ng-container>
</ng-template>

<ng-template #evidenceDetails let-modal>
    <div class="modal-header text-center">
        <button type="button" (click)="modal.dismiss()" class="close">
            <img src="assets/img/icons/close_icon.svg" alt="" />
        </button>
    </div>
    <div class="card evidence_details">
        <img *ngIf="openEvidenceDetails.type == 2" class="card-img-top" [src]="openEvidenceDetails.url"
            [alt]="openEvidenceDetails.title">
        <pdf-viewer *ngIf="openEvidenceDetails.type == 1" [src]="openEvidenceDetails.url" [render-text]="true"
            style="display: block;"></pdf-viewer>
        <div class="card-body">
            <h5 class="card-title"> {{openEvidenceDetails.title}} </h5>
            <p class="card-text" style="text-align: justify;"> {{openEvidenceDetails.description}} </p>
        </div>
    </div>
</ng-template>

<!-- <canvas id="canvas" style="overflow:auto"></canvas> -->