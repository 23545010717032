import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  dashboardData;

  constructor(private apiService: ApiService) {
  }

  ngOnInit(): void {
    this.intialHits();
  }

  async intialHits() {
    await this.getDashboardData();
  }

  async getDashboardData() {
    let temp = await this.apiService.getDashboardData({}).toPromise();
    this.dashboardData = temp.data;
  }
}
