import { REGEX, EVIDENCE_TYPES, TYPE_SELECT_OPTIONS } from './../../constants';
import { INTERNAL_PATHS } from 'src/app/constants';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/core/services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import { SwalService } from 'src/app/common/dialogue-services/swal.service';

@Component({
  selector: 'app-add-or-edit-evidence',
  templateUrl: './add-or-edit-evidence.component.html',
  styleUrls: ['./add-or-edit-evidence.component.css']
})
export class AddOrEditEvidenceComponent implements OnInit {
  uploading = false;
  categoryId;
  name;
  evidenceId;
  evidence;
  category;

  loading = false;

  evidenceEditingFlag = false;
  public evidenceForm: FormGroup;
  constructor(private apiService: ApiService, private router: Router, private formBuilder: FormBuilder, private route: ActivatedRoute, private swal: SwalService) {
    this.evidenceForm = this.formBuilder.group({
      title: ['', Validators.compose([Validators.required, Validators.pattern(REGEX.NO_WHITESPACE_REGEX)])],
      description: ['', Validators.compose([Validators.required, Validators.pattern(REGEX.DESCRIPTION_REGEX)])],
      url: ['', Validators.required],
      type: ['', Validators.required],
      videoType: ['', Validators.required],
      thumbnail: [''],
      categoryId: ['', Validators.required]
    })
  }

  ngOnInit(): void {
    if (this.router.url.includes(`/edit`)) {
      this.evidenceEditingFlag = true;
      this.route.params.subscribe(params => {
        this.evidenceId = params.evidenceId;
        this.apiService.getEvidence(this.evidenceId).subscribe(res => {
          this.evidence = res.data;
          this.evidenceForm.controls['title'].patchValue(this.evidence.title)
          this.evidenceForm.controls['url'].patchValue(this.evidence.url)
          this.evidenceForm.controls['description'].patchValue(this.evidence.description)
          this.evidenceForm.controls['categoryId'].patchValue(this.evidence.categoryId)
          this.evidenceForm.controls['videoType'].patchValue(this.evidence.videoType)
          this.evidenceForm.controls['type'].patchValue(this.evidence.type)
          $('.file-custom').text(this.evidence.url);
        })
      })
    }
    this.route.params.subscribe(params => {
      this.categoryId = params.categoryId;
      this.name = params.categoryName;
      this.evidenceForm.controls['type'].patchValue(EVIDENCE_TYPES.VIDEO)
    })
  }

  navigateToItemType() {
    let url = `${INTERNAL_PATHS.ADMIN}/${INTERNAL_PATHS.CATEGORY_WISE_EVIDENCES}`;
    url = url.replace(":categoryId", this.categoryId);
    this.router.navigateByUrl(url);
  }

  async upload(event) {
    let file = event.target.files[0];
    this.uploading = true;
    this.evidenceForm.controls['videoType'].patchValue(file.type);
    // for thumbnail
    let cover: any = await this.getVideoCover(file, 8.75);
    let fileNew = new File([cover], `${file.name}-cover.jpg`, { type: "image/jpeg", lastModified: Date.now() });
    // for video uploading
    let coverData = await this.apiService.fileUpload(fileNew).toPromise();
    this.evidenceForm.controls['thumbnail'].patchValue(coverData.data.Location);
    // for cover image uploading
    let videoData = await this.apiService.fileUpload(file).toPromise();
    this.evidenceForm.controls['url'].patchValue(videoData.data.Location);
    this.uploading = false;
    $('.file-custom').text(file.name);
  }

  submitOperation() {
    if (this.evidenceEditingFlag) {
      this.updateEvidence();
    } else {
      this.addEvidence();
    }
  }

  addEvidence() {
    this.evidenceForm.controls['categoryId'].patchValue(this.categoryId);
    if (this.evidenceForm.valid) {
      this.loading = true;
      this.apiService.addEvidence(this.evidenceForm.value).subscribe(res => {
        this.loading = false;
        let url = INTERNAL_PATHS.CATEGORY_WISE_EVIDENCES;
        url = url.replace(":categoryId", this.categoryId);
        this.router.navigateByUrl(`${INTERNAL_PATHS.ADMIN}/${url}`);
      })
    } else {
      Object.keys(this.evidenceForm.controls).forEach(key => {
        this.evidenceForm.controls[key].markAsTouched({ onlySelf: true });
      })
    }
  }

  updateEvidence() {
    this.evidenceForm.controls['categoryId'].patchValue(this.categoryId);
    if (this.evidenceForm.valid) {
      this.apiService.updateEvidence(this.evidenceForm.value).subscribe(res => {
        let url = INTERNAL_PATHS.CATEGORY_WISE_EVIDENCES;
        url = url.replace(":categoryId", this.categoryId);
        this.router.navigateByUrl(`${INTERNAL_PATHS.ADMIN}/${url}`);
      })
    } else {
      Object.keys(this.evidenceForm.controls).forEach(key => {
        this.evidenceForm.controls[key].markAsTouched({ onlySelf: true });
      })
    }
  }

  /**
   * method to capture frame from a video
   */
  getVideoCover(file, seekTo = 0.0) {
    return new Promise((resolve, reject) => {
      // load the file to a video player
      const videoPlayer = document.createElement('video');
      videoPlayer.setAttribute('src', URL.createObjectURL(file));
      videoPlayer.load();
      videoPlayer.addEventListener('error', (ex) => {
        // reject("error when loading video file", ex);
      });
      // load metadata of the video to get video duration and dimensions
      videoPlayer.addEventListener('loadedmetadata', () => {
        // seek to user defined timestamp (in seconds) if possible
        if (videoPlayer.duration < seekTo) {
          reject("video is too short.");
          return;
        }
        // delay seeking or else 'seeked' event won't fire on Safari
        setTimeout(() => {
          videoPlayer.currentTime = seekTo;
        }, 200);
        // extract video thumbnail once seeking is complete
        videoPlayer.addEventListener('seeked', () => {
          console.log('video is now paused at %ss.', seekTo);
          // define a canvas to have the same dimension as the video
          const canvas = document.createElement("canvas");
          canvas.width = videoPlayer.videoWidth;
          canvas.height = videoPlayer.videoHeight;
          // draw the video frame to canvas
          const ctx = canvas.getContext("2d");
          ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
          // return the canvas image as a blob
          ctx.canvas.toBlob(
            blob => {
              resolve(blob);
            },
            "image/jpeg",
            0.75 /* quality */
          );
        });
      });
    });
  }

}
