<div class="card user_table box_vh">
    <div
        class="card-header d-flex justify-content-start justify-content-md-between align-items-start align-items-md-center">
        <div class="breadcrumbs d-flex">
            <h3 class="">User Requests</h3>
        </div>
    </div>
    <div class="card-body" *ngIf="userRequests?.length > 0; else noRecords">
        <div class="table-responsive">
            <table class="table table-hover" id="table_data" style="width:100%">
                <thead>
                    <tr>
                        <th>Name
                            <div class="sorting">
                                <i (click)="sorting('name', 1)" [class]="active_class === 'name1' ? 'active' : ''"><img
                                        src="/assets/img/icons/arw_up.png" alt="sort" /></i>
                                <i (click)="sorting('name', -1)"
                                    [class]="active_class === 'name-1' ? 'active' : ''"><img
                                        src="/assets/img/icons/arw_down.png" alt="sort" /></i>
                            </div>
                        </th>
                        <th>Email
                            <div class="sorting">
                                <i (click)="sorting('email', 1)"
                                    [class]="active_class === 'email1' ? 'active' : ''"><img
                                        src="/assets/img/icons/arw_up.png" alt="sort" /></i>
                                <i (click)="sorting('email', -1)"
                                    [class]="active_class === 'email-1' ? 'active' : ''"><img
                                        src="/assets/img/icons/arw_down.png" alt="sort" /></i>
                            </div>
                        </th>
                        <th>ID No
                            <div class="sorting">
                                <i (click)="sorting('fsi', 1)" [class]="active_class === 'fsi1' ? 'active' : ''"><img
                                        src="/assets/img/icons/arw_up.png" alt="sort" /></i>
                                <i (click)="sorting('fsi', -1)" [class]="active_class === 'fsi-1' ? 'active' : ''"><img
                                        src="/assets/img/icons/arw_down.png" alt="sort" /></i>
                            </div>
                        </th>
                        <th>Request Date
                            <div class="sorting">
                                <i (click)="sorting('createdAt', 1)"
                                    [class]="active_class === 'createdAt1' ? 'active' : ''"><img
                                        src="/assets/img/icons/arw_up.png" alt="sort" /></i>
                                <i (click)="sorting('createdAt', -1)"
                                    [class]="active_class === 'createdAt-1' ? 'active' : ''"><img
                                        src="/assets/img/icons/arw_down.png" alt="sort" /></i>
                            </div>
                        </th>
                        <th>Approval </th>
                        <th class="text-right">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of userRequests">
                        <tr>
                            <td class="user_info">
                                <i class="icon">
                                    <img [src]="item.profilePicture || 'assets/img/user_logo.png'" [alt]="item.name" />
                                </i>
                                {{item.name}}
                            </td>
                            <td>{{item.email}}</td>
                            <td>{{item.fsi}}</td>
                            <td>{{item.createdAt | date: 'mediumDate'}}</td>
                            <td>
                                <span *ngIf="!item.isVerified">
                                    <button class="btn btn-sm btn-primary" type="button"
                                        (click)="approveUser(item._id)">
                                        Verify
                                    </button>
                                </span>
                            </td>
                            <td class="text-right">
                                <div class="action_btn">
                                    <i class="del_icon" (click)="deleteUserRequest(item._id)"><img
                                            src="assets/img/icons/delete_icon.svg" alt="" /></i>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
        <div class="pagination">
            <app-pagination [collectionSize]="collectionSize" [page]="page" [pageSize]="pageSize"
                (pageChangeEvent)="pageChangeEvent($event)"></app-pagination>
        </div>
    </div>
</div>

<ng-template #noRecords>
    <div *ngIf="!loading" class="no_user_req">
        <img src="/assets/img/icons/disapproved_users.svg">
        <p> No user requests yet !!! </p>
    </div>
</ng-template>