import { CommonService } from './../../core/services/common.service';
import { Component, OnInit } from '@angular/core';
import { SwalService } from 'src/app/common/dialogue-services/swal.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { AUTH_PATHS, INTERNAL_PATHS } from 'src/app/constants';
import { ApiService } from 'src/app/core/services/api.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  admin;

  LINKS = {
    ACCOUNT_SETTINGS: `/${INTERNAL_PATHS.ADMIN}/${INTERNAL_PATHS.ACCOUNT_SETTINGS}`
  }

  constructor(private swal: SwalService, private apiService: ApiService, private authService: AuthService, private router: Router, private commonService: CommonService) {
    this.commonService.getAccountUpdates().subscribe(res => {
      this.getAdmin();
    })
  }

  ngOnInit(): void {
    this.getAdmin();
  }

  getAdmin() {
    this.apiService.getAdmin().subscribe(res => {
      this.admin = res.data;
    })
  }

  async logout() {
    let swal_data = await this.swal.logoutSwal();
    if (swal_data.value) {
      this.authService.logout().subscribe(res => {
        localStorage.clear();
        this.router.navigateByUrl(AUTH_PATHS.SIGN_IN)
      })
    }
  }

  openSidebar() {
    this.commonService.toggleClass();
  }
}
