import { ADMIN_APIS } from './../../constants';
import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private httpService: HttpService) { }

  getCategories() {
    return this.httpService.getData(ADMIN_APIS.GET_CATEGORIES, {});
  }

  fetchCategoryById(data) {
    return this.httpService.getData(ADMIN_APIS.FETCH_CATEGORY_BY_ID, data);
  }

  getAdmin() {
    return this.httpService.getData(ADMIN_APIS.GET_ADMIN);
  }

  addCategory(data) {
    return this.httpService.postData(ADMIN_APIS.ADD_CATEGORY, data)
  }

  deleteCategory(id) {
    return this.httpService.deleteData(ADMIN_APIS.DELETE_CATEGORY, id)
  }

  getUserList(query) {
    return this.httpService.getData(ADMIN_APIS.GET_USER_LIST, query);
  }

  getDashboardData(query) {
    return this.httpService.getData(ADMIN_APIS.DASHBOARD_DATA, query);
  }

  udpateCategory(data) {
    return this.httpService.putData(ADMIN_APIS.UPDATE_CATEGORY, data)
  }

  fileUpload(file) {
    let formData = new FormData()
    formData.append('file', file);
    return this.httpService.postData(ADMIN_APIS.FILE_UPLOAD, formData);
  }

  // evidence
  getCategoriesWithEvidenceCount() {
    return this.httpService.getData(ADMIN_APIS.GET_EVIDENCE_CATEGORY_WISE);
  }

  addEvidence(data) {
    return this.httpService.postData(ADMIN_APIS.ADD_EVIDENCE, data);
  }

  deleteEvidence(id) {
    return this.httpService.deleteData(ADMIN_APIS.DELETE_EVIDENCE, id);
  }

  getEvidence(id) {
    let url = ADMIN_APIS.GET_EVIDENCE_BY_ID;
    url = url.replace(":id", id);
    return this.httpService.getData(url);
  }

  updateEvidence(data) {
    return this.httpService.putData(ADMIN_APIS.EDIT_EVIDENCE, data);
  }

  getEvidencesByCategory(categoryId) {
    let url = ADMIN_APIS.GET_EVIDENCES_BY_CATEGORY;
    url = url.replace(":categoryId", categoryId);
    return this.httpService.getData(url, categoryId);
  }

  // user APIS
  deleteUser(id) {
    return this.httpService.deleteData(ADMIN_APIS.DELETE_USER, id);
  }

  getUserRequests(query) {
    return this.httpService.getData(ADMIN_APIS.GET_USER_REQUESTS, query);
  }

  addUser(data) {
    return this.httpService.postData(ADMIN_APIS.ADD_USER, data);
  }

  approveUser(userId) {
    return this.httpService.putData(ADMIN_APIS.APPROVE_USER, { userId });
  }

  // Forms
  addForm(data) {
    return this.httpService.postData(ADMIN_APIS.FORMS, data);
  }

  getForms(query?) {
    return this.httpService.getData(ADMIN_APIS.FORMS, query);
  }

  updateForm(data) {
    return this.httpService.putData(ADMIN_APIS.FORMS, data);
  }

  deleteForm(id) {
    return this.httpService.deleteData(ADMIN_APIS.FORMS, id);
  }

  // Domains
  addDomain(data) {
    return this.httpService.postData(ADMIN_APIS.DOMAIN, data);
  }

  getDomains() {
    return this.httpService.getData(ADMIN_APIS.DOMAIN);
  }

  updateDomain(data) {
    return this.httpService.putData(ADMIN_APIS.DOMAIN, data);
  }

  deleteDomain(id) {
    return this.httpService.deleteData(ADMIN_APIS.DOMAIN, id);
  }

  // General information
  addInfo(info) {
    return this.httpService.postData(ADMIN_APIS.GENERAL_INFORMATION, info);
  }

  listInfo() {
    return this.httpService.getData(ADMIN_APIS.LIST_GENERAL_INFORMATION);
  }

  getInfoByID(query) {
    return this.httpService.getData(ADMIN_APIS.GENERAL_INFORMATION, query);
  }

  deleteInfo(id) {
    return this.httpService.deleteData(ADMIN_APIS.GENERAL_INFORMATION, id);
  }

  updateInfo(data) {
    return this.httpService.putData(ADMIN_APIS.GENERAL_INFORMATION, data);
  }

  /**
   * checkRSA
   */
  encryptKey() {
    return this.httpService.getData(ADMIN_APIS.ENCRYPT_KEY)
  }

}
