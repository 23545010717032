import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {

  @Output() pageChangeEvent = new EventEmitter<any>();

  maxSize = 5; // pagination size
  rotate = true;
  ellipses = true;
  boundaryLinks = true;
  @Input() collectionSize; // number of rows
  @Input() page;
  @Input() pageSize;

  constructor() { }

  ngOnInit(): void {
  }

  onPageChange(e) {
    this.pageChangeEvent.emit(e)
  }
}
