import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import * as _ from 'lodash'
import * as crypto from "crypto-js";
// import * as crypto from "crypto";
import * as forge from 'node-forge';

@Injectable({
  providedIn: 'root'
})
export class RsaService {

  private AESOptions = {
    mode: crypto.mode.ECB,
    padding: crypto.pad.Pkcs7
  }

  private aesKey;

  constructor() {
    let privateKey = forge.pki.privateKeyFromPem(environment.RSA_PRIVATE_KEY);
    this.aesKey = privateKey.decrypt(forge.util.decode64(environment.AES_KEY));
    
  }

  encryptData(data) {
    Object.keys(data).forEach(key => {
      // if (key !== 'type') {
      data[key] = crypto.AES.encrypt(data[key].toString(), this.aesKey, this.AESOptions).toString();
      // }
    })
    return data;
  }

  decryptData(data) {
    if (_.isArray(data)) {
      data.forEach((item, i) => {
        Object.keys(data[i]).forEach((key => {
          let temp = crypto.AES.decrypt(data[i][key], this.aesKey, this.AESOptions).toString(crypto.enc.Utf8);
          data[i][key] = temp;
          if (key == 'isVerified' || key == 'isArchived') {
            if (data[i][key] === 'true') {
              data[i][key] = true;
            } else if (data[i][key] === 'false') {
              data[i][key] = false
            }
          }
        }))
      })
    } else if (_.isPlainObject(data)) {
      Object.keys(data).forEach((key => {
        data[key] = crypto?.AES?.decrypt(data[key], this.aesKey, this.AESOptions)?.toString(crypto?.enc?.Utf8);
        if (key == 'isVerified' || key == 'isArchived') {
          if (data[key] === 'true') {
            data[key] = true;
          } else if (data[key] === 'false') {
            data[key] = false
          }
        }
      }))
    }
    return data;
  }
}
