<div class="card user_table default_block box_vh">
    <div
        class="card-header d-flex justify-content-start justify-content-md-between align-items-start align-items-md-center">
        <div class="breadcrumbs d-flex">
            <h3 class="" style="cursor:pointer;" (click)="navigateToItemType()">Item Type</h3>
            <span *ngIf="!evidenceEditingFlag"><i><img src="assets/img/icons/right_arrow_icon.svg" alt="" /></i>
                {{name}}
            </span>
            <span *ngIf="!evidenceEditingFlag"><i><img src="assets/img/icons/right_arrow_icon.svg" alt="" /></i> Add New
                Evidence
            </span>
            <span *ngIf="evidenceEditingFlag"><i><img src="assets/img/icons/right_arrow_icon.svg" alt="" /></i>
                {{evidence?.title}}
            </span>
            <span *ngIf="evidenceEditingFlag"><i><img src="assets/img/icons/right_arrow_icon.svg" alt="" /></i>
                Edit
            </span>
        </div>
    </div>

    <div class="card-body">
        <div class="form_default py-3">
            <form [formGroup]="evidenceForm" (submit)="submitOperation()">
                <div class="form-group">
                    <label>Title</label>
                    <input type="text" class="form-control" formControlName="title" placeholder="Enter video title">
                    <div class="error_box text-danger"
                        *ngIf="evidenceForm.controls['title'].hasError('required') && evidenceForm.controls['title'].touched">
                        Please enter title
                    </div>
                    <div class="error_box text-danger"
                        *ngIf="evidenceForm.controls['title'].hasError('pattern') && evidenceForm.controls['title'].touched">
                        Please enter valid title
                    </div>
                </div>
                <div class="form-group">
                    <label>Description</label>
                    <textarea class="form-control" formControlName="description"
                        placeholder="Enter description"></textarea>
                    <div class="error_box text-danger"
                        *ngIf="evidenceForm.controls['description'].hasError('required') && evidenceForm.controls['description'].touched">
                        Please enter description
                    </div>
                    <div class="error_box text-danger"
                        *ngIf="evidenceForm.controls['description'].hasError('pattern') && evidenceForm.controls['description'].touched">
                        Please enter valid description
                    </div>
                </div>
                <div class="form-group">
                    <label>Upload Video</label>
                    <div class="file mb-4">
                        <!-- <label class="file-custom">Choose File...</label> -->
                        <input type="file" id="file" accept="video/*" aria-label="File browser example"
                            (change)="upload($event)">
                        <label class="file-custom" for="file">
                            <span class="file-choose">Choose File...</span>
                            <button class="btn">Choose File</button>
                        </label>
                        <div class="error_box text-danger"
                            *ngIf="evidenceForm.controls['url'].hasError('required') && evidenceForm.controls['url'].touched">
                            Please enter url
                        </div>
                    </div>
                </div>

                <div class="text-center mt-4 mt-md-4">
                    <button type="submit" [disabled]="loading" class="btn btn-lg btn-primary">
                        <ng-container *ngIf="uploading"> Uploading video... </ng-container>
                        <ng-container *ngIf="!uploading && !loading"> Submit </ng-container>
                        <ng-container *ngIf="loading"> Submiting... </ng-container>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>