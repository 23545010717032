import { AUTH_PATHS } from './../../constants';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-reset-password-successfully',
  templateUrl: './admin-reset-password-successfully.component.html',
  styleUrls: ['./admin-reset-password-successfully.component.css']
})
export class AdminResetPasswordSuccessfullyComponent implements OnInit {

  LINKS = {
    SIGN_IN: `/${AUTH_PATHS.SIGN_IN}`
  }

  constructor() { }

  ngOnInit(): void {
  }

}
