import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-link-sent-successfully',
  templateUrl: './link-sent-successfully.component.html',
  styleUrls: ['./link-sent-successfully.component.css']
})
export class LinkSentSuccessfullyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
