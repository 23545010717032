import { RsaService } from './rsa.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private API_SERVER = environment.apiUrl;

  headers = new HttpHeaders({
    'Accept': "application/vnd.softswiss.v1+json",
    'Content-Type': 'application/json',
    'Accept-Language': 'en'
  });

  constructor(private httpClient: HttpClient, private rsa: RsaService) {
  }

  public getData(apiPath: string, data: any = {}) {
    let query = '';
    let url;
    Object.keys(data).length > 0 && Object.keys(data).forEach((item, i) => {
      query = `${query}${item}=${data[item]}`
      if (i < Object.keys(data).length - 1) {
        query = `${query}&`
      }
    })
    if (Object.keys(data).length > 0) {
      url = `${this.API_SERVER}${apiPath}?${query}`
    } else {
      url = `${this.API_SERVER}${apiPath}`
    }
    return this.httpClient.get<any>(url)
  }

  public postData(apiPath: string, data?: any) {
    data = this.rsa.encryptData(data);
    return this.httpClient.post<any>(`${this.API_SERVER}${apiPath}`, data)
  }

  public putData(apiPath: string, data: any) {
    data = this.rsa.encryptData(data);
    return this.httpClient.put<any>(`${this.API_SERVER}${apiPath}`, data)
  }

  public deleteData(apiPath: string, id: string) {
    return this.httpClient.delete<any>(`${this.API_SERVER}${apiPath}/${id}`)
  }

  public postFormData(apiPath: string, data?: any) {
    // data = this.rsa.encryptData(data);
    return this.httpClient.post<any>(`${this.API_SERVER}${apiPath}`, data,
      {
        headers: new HttpHeaders({
          'Content-Type': 'multipart/form-data'
        })
      });
  }
}
