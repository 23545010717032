import { ResetPinSuccessfullyComponent } from './auth/reset-pin-successfully/reset-pin-successfully.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignInComponent } from "./auth/sign-in/sign-in.component";
import { AUTH_PATHS, INTERNAL_PATHS } from './constants';
import { AuthGuardService } from './core/services/auth-guard.service';
import { ResetPinComponent } from './auth/reset-pin/reset-pin.component'
import { ExternalAuthguardService } from './core/services/external-auth-guard.service';
import { ResetPasswordAdminComponent } from './auth/reset-password-admin/reset-password-admin.component';
import { AdminResetPasswordSuccessfullyComponent } from './auth/admin-reset-password-successfully/admin-reset-password-successfully.component';
import { LinkSentSuccessfullyComponent } from './auth/link-sent-successfully/link-sent-successfully.component';

const routes: Routes = [
  { path: '', redirectTo: AUTH_PATHS.SIGN_IN, pathMatch: 'full' },
  { path: AUTH_PATHS.SIGN_IN, canActivate: [ExternalAuthguardService], component: SignInComponent },
  { path: AUTH_PATHS.FORGOT_PASSWORD, canActivate: [ExternalAuthguardService], component: ForgotPasswordComponent },
  { path: AUTH_PATHS.RESET_PIN_BY_TOKEN, component: ResetPinComponent },
  { path: AUTH_PATHS.PIN_RESET_SUCCESSFULLY, component: ResetPinSuccessfullyComponent },
  { path: AUTH_PATHS.PASSWORD_RESET_SUCCESSFULLY, component: AdminResetPasswordSuccessfullyComponent },
  { path: AUTH_PATHS.LINK_SENT_SUCCESSFULLY, component: LinkSentSuccessfullyComponent },
  { path: AUTH_PATHS.RESET_PASSWORD_BY_TOKEN, component: ResetPasswordAdminComponent },
  { path: INTERNAL_PATHS.ADMIN, canActivate: [AuthGuardService], loadChildren: () => import('./internal/internal.module').then(m => m.InternalModule) },
  { path: '**', pathMatch: 'full', redirectTo: AUTH_PATHS.SIGN_IN }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
