<div class="front-forms">
    <div class="container-fluid">
        <div class="login_box">
            <div class="front-logo text-center">
                <img src="assets/img/logo.png" alt="KopShop" />
            </div>

            <div *ngIf="invalid_token" class="form-box invalid_token">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                <span>
                    {{content}}
                </span>
            </div>

            <form *ngIf="!invalid_token" class="form-box" [formGroup]="resetPinForm" (submit)="resetPin()">
                <h2 class="text-center mb-0">Reset PIN</h2>
                <p class="text-center pb-3">Enter your new PIN.</p>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <img src="assets/img/icons/password.svg" alt="KopShop" width="22" />
                                    </span>
                                </div>
                                <input maxlength="4" [readonly]="invalid_token" formControlName="newPin" type="password"
                                    class="form-control" placeholder="Enter new PIN">
                            </div>
                            <div *ngIf="
                            resetPinForm.controls['newPin'].hasError('required') &&
                            resetPinForm.controls['newPin'].touched" class="text-danger">
                                {{FORM_ERRORS.NEW_PIN_REQUIRED}}
                            </div>
                            <div *ngIf="
                            resetPinForm.controls['newPin'].hasError('pattern') &&
                            resetPinForm.controls['newPin'].touched" class="text-danger">
                                {{FORM_ERRORS.INVALID_PIN}}
                            </div>
                            <div *ngIf="
                            resetPinForm.controls['newPin'].hasError('minlength') &&
                            resetPinForm.controls['newPin'].touched" class="text-danger">
                                {{FORM_ERRORS.MIN_CHAR}}
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <img src="assets/img/icons/password.svg" alt="KopShop" width="22" />
                                    </span>
                                </div>
                                <input maxlength="4" [readonly]="invalid_token" formControlName="confirmPin"
                                    type="password" class="form-control" placeholder="Confirm new PIN">
                            </div>
                            <div *ngIf="
                            resetPinForm.controls['confirmPin'].hasError('required') &&
                            resetPinForm.controls['confirmPin'].touched" class="text-danger">
                                {{FORM_ERRORS.CONFIRM_PIN_REQUIRED}}
                            </div>
                            <div *ngIf="
                            resetPinForm.controls['confirmPin'].hasError('pattern') &&
                            resetPinForm.controls['confirmPin'].touched" class="text-danger">
                                {{FORM_ERRORS.INVALID_PIN}}
                            </div>
                            <div *ngIf="
                            resetPinForm.controls['confirmPin'].hasError('minlength') &&
                            resetPinForm.controls['confirmPin'].touched" class="text-danger">
                                {{FORM_ERRORS.MIN_CHAR}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="mt-2 mt-lg-3">
                            <button [disabled]="invalid_token" class="w-100 btn btn-lg btn-primary text-capitalize"
                                type="submit">Change
                                PIN</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>





<!-- <div class="card signin-card">
    <div class="front-forms">
        <div class="container-fluid">
            <div class="login_box">

                <form [formGroup]="resetPinForm" (submit)="resetPin()" class="form-box" action="" method="post">
                    <h2 class="text-center">Reset Pin</h2>
                    <div class="row">
                        <div class="col-12">
                            <label>New Pin</label><br>
                            <div class="form-group">
                                <input maxlength="4" formControlName="newPin" type="password" class="form-control">
                                <div *ngIf="
                                resetPinForm.controls['newPin'].hasError('required') &&
                                resetPinForm.controls['newPin'].touched" class="text-danger">
                                    {{FORM_ERRORS.NEW_PIN_REQUIRED}}
                                </div>
                                <div *ngIf="
                                resetPinForm.controls['newPin'].hasError('pattern') &&
                                resetPinForm.controls['newPin'].touched" class="text-danger">
                                    {{FORM_ERRORS.MIN_CHAR}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <label>Confirm New Pin</label>
                            <div class="form-group">
                                <input maxlength="4" formControlName="confirmPin" type="password" class="form-control">
                                <div *ngIf="
                                resetPinForm.controls['confirmPin'].hasError('required') &&
                                resetPinForm.controls['confirmPin'].touched" class="text-danger">
                                    {{FORM_ERRORS.CONFIRM_PIN_REQUIRED}}
                                </div>
                                <div *ngIf="
                                resetPinForm.controls['confirmPin'].hasError('pattern') &&
                                resetPinForm.controls['confirmPin'].touched" class="text-danger">
                                    {{FORM_ERRORS.MIN_CHAR}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="mt-2 mt-lg-3">
                                <button class="w-100 btn btn-lg btn-primary text-capitalize"
                                    type="submit">Submit</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div> -->