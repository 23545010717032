import { ChangePasswordComponent } from './change-password/change-password.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { INTERNAL_PATHS, AUTH_PATHS } from '../constants';
import { InternalComponent } from './internal.component';
import { AuthGuardService } from '../core/services/auth-guard.service';
import { CategoriesComponent } from './categories/categories.component';
import { UserRequestComponent } from './user-request/user-request.component';
import { FormsComponent } from './forms/forms.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EvidenceListingComponent } from './evidence-listing/evidence-listing.component';
import { EvidenceDetailsComponent } from './evidence-details/evidence-details.component';
import { AddOrEditEvidenceComponent } from './add-or-edit-evidence/add-or-edit-evidence.component';
import { GeneralInformationComponent } from './general-information/general-information.component';
import { EvidenceComponent } from './evidence/evidence.component';
import { DomainComponent } from './domain/domain.component';
import { GeneralInformationFormComponent } from './general-information-form/general-information-form.component';

const routes: Routes = [
  {
    path: '', canActivate: [AuthGuardService], component: InternalComponent, children: [
      { path: '', redirectTo: INTERNAL_PATHS.DASHBOARD, pathMatch: 'full' },
      { path: INTERNAL_PATHS.DASHBOARD, component: DashboardComponent },
      { path: INTERNAL_PATHS.USER_REQUEST, component: UserRequestComponent },
      { path: INTERNAL_PATHS.CATEGORIES, component: CategoriesComponent },
      { path: INTERNAL_PATHS.EVIDENCE, component: EvidenceComponent },
      { path: INTERNAL_PATHS.CATEGORY_WISE_EVIDENCES, component: EvidenceListingComponent },
      { path: INTERNAL_PATHS.EVIDENCE_DETAILS, component: EvidenceDetailsComponent },
      { path: INTERNAL_PATHS.EVIDENCE_ADD, component: AddOrEditEvidenceComponent },
      { path: INTERNAL_PATHS.EVIDENCES_EDIT, component: AddOrEditEvidenceComponent },
      { path: INTERNAL_PATHS.FORMS, component: FormsComponent },
      { path: INTERNAL_PATHS.GENERAL_INFORMATION, component: GeneralInformationComponent },
      { path: INTERNAL_PATHS.GENERAL_INFORMATION_ADD, component: GeneralInformationFormComponent },
      { path: INTERNAL_PATHS.GENERAL_INFORMATION_EDIT, component: GeneralInformationFormComponent },
      { path: INTERNAL_PATHS.DOMAIN, component: DomainComponent },
      { path: INTERNAL_PATHS.ACCOUNT_SETTINGS, component: ChangePasswordComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InternalRoutingModule { }
