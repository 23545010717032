import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SwalService {

  constructor() { }

  async logoutSwal() {
    return await Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#e2e6ea',
      cancelButtonColor: '#76797c',
      confirmButtonText: 'Log out'
    })
  }

  async deleteSwal(title) {
    return await Swal.fire({
      title,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#e2e6ea',
      cancelButtonColor: '#76797c',
      confirmButtonText: 'Delete'
    })
  }

  async successSwal(title) {
    return await Swal.fire({
      title,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#e2e6ea',
      cancelButtonColor: '#76797c',
      confirmButtonText: 'Ok'
    })
  }

  async changePasswordSwal(title) {
    return await Swal.fire({
      title,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#e2e6ea',
      cancelButtonColor: '#76797c',
      confirmButtonText: 'Ok'
    })
  }
}