import { INTERNAL_PATHS } from './../../constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/core/services/api.service';

@Component({
  selector: 'app-general-information-form',
  templateUrl: './general-information-form.component.html',
  styleUrls: ['./general-information-form.component.css']
})
export class GeneralInformationFormComponent implements OnInit {

  public infoForm: FormGroup;

  editFlag = false;
  infoId;

  LINKS = {
    GENERAL_INFORMATION: `/${INTERNAL_PATHS.ADMIN}/${INTERNAL_PATHS.GENERAL_INFORMATION}`
  }
  loading = false;

  constructor(private apiService: ApiService, private router: Router, private formBuilder: FormBuilder, private route: ActivatedRoute) {
    this.infoForm = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required]
    })
  }

  ngOnInit(): void {
    if (this.router.url.includes("/edit")) {
      this.editFlag = true;
      this.route.params.subscribe(params => {
        this.infoId = params.id;
        this.apiService.getInfoByID({ id: this.infoId }).subscribe(res => {
          this.infoForm.controls['title'].patchValue(res.data.title);
          this.infoForm.controls['description'].patchValue(res.data.description);
        })
      })
    }
  }

  submitOperation() {
    if (this.editFlag) {
      this.updateInfo()
    } else {
      this.addInfo()
    }
  }

  addInfo() {
    this.infoForm.controls['title'].patchValue(this.infoForm.value.title.trim())
    this.infoForm.controls['description'].patchValue(this.infoForm.value.description.trim())
    if (this.infoForm.valid) {
      this.loading = true;
      this.apiService.addInfo(this.infoForm.value).subscribe(res => {
        this.loading = false;
        this.router.navigateByUrl(`/${INTERNAL_PATHS.ADMIN}/${INTERNAL_PATHS.GENERAL_INFORMATION}`)
      })
    } else {
      Object.keys(this.infoForm.controls).forEach(key => {
        this.infoForm.controls[key].markAsTouched({ onlySelf: true })
      })
    }
  }

  updateInfo() {
    this.infoForm.controls['title'].patchValue(this.infoForm.value.title.trim())
    this.infoForm.controls['description'].patchValue(this.infoForm.value.description.trim())
    if (this.infoForm.valid) {
      this.loading = true;
      this.apiService.updateInfo({ ...this.infoForm.value, ...{ id: this.infoId } }).subscribe(res => {
        this.loading = false;
        this.router.navigateByUrl(`/${INTERNAL_PATHS.ADMIN}/${INTERNAL_PATHS.GENERAL_INFORMATION}`)
      })
    } else {
      Object.keys(this.infoForm.controls).forEach(key => {
        this.infoForm.controls[key].markAsTouched({ onlySelf: true })
      })
    }
  }

}
