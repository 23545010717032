<div *ngIf="evidence" class="card user_table default_block box_vh">
    <div
        class="card-header d-flex justify-content-start justify-content-md-between align-items-start align-items-md-center">
        <div class="breadcrumbs d-flex">
            <h3 class="">Item Type</h3>
            <b (click)="navigateToItemTypes()"><span><i><img src="assets/img/icons/right_arrow_icon.svg" alt="" /></i>
                    {{category?.name}}</span></b>
            <span><i><img src="assets/img/icons/right_arrow_icon.svg" alt="" /></i> {{evidence.title}}</span>
        </div>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <span class="video_ico">
                    <video width="320" height="240" controls autoplay>
                        <source [src]="evidence.url" [type]="evidence.videoType">
                    </video>
                    <!-- <div class="buttons">
                        <button class="uk-button uk-button-primary first" onclick="playVid()" type="button">
                            <i class="fa fa-play" aria-hidden="true"></i></button>
                        <button class="uk-button uk-button-primary second" onclick="pauseVid()" type="button">
                            <i class="fa fa-pause" aria-hidden="true"></i></button>
                    </div> -->
                </span>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="intro_details">
                    <h3>{{evidence.title}}</h3>
                    <!-- <p><b>{{evidence.shortDescription}}</b></p> -->
                    <p>{{evidence.description}}</p>
                </div>
            </div>
        </div>
    </div>
</div>