import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reset-pin-successfully',
  templateUrl: './reset-pin-successfully.component.html',
  styleUrls: ['./reset-pin-successfully.component.css']
})
export class ResetPinSuccessfullyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
