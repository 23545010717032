import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-evidence-kits',
  templateUrl: './evidence-kits.component.html',
  styleUrls: ['./evidence-kits.component.css']
})
export class EvidenceKitsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
