import { ERROR_MSG } from './../../constants';
import { Injectable } from '@angular/core';
import { Router, CanActivate, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { INTERNAL_PATHS, AUTH_PATHS } from '../../constants';
import { ToastrService } from '../../common/toastr/toastr.service';

export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class AuthGuardService implements CanActivate, CanDeactivate<CanComponentDeactivate> {
    constructor(
        private router: Router,
        private toastrService: ToastrService
    ) {
    }

    canActivate(): boolean {
        if (!this.isAuthenticated()) {
            this.router.navigateByUrl(`/${AUTH_PATHS.SIGN_IN}`);
            return false;
        } else {
            return true;
        }
    }

    canDeactivate(component: CanComponentDeactivate) {
        return component.canDeactivate ? component.canDeactivate() : true;
    }

    loginToProceed() {
        this.toastrService.showToastr({ type: 'warning', message: ERROR_MSG.LOGIN_TO_PROCEED })
    }

    isAuthenticated(): boolean {
        const token = localStorage.getItem('accessToken');
        if (token) {
            return true;
        } else {
            this.loginToProceed();
            return false;
        }
    }
}