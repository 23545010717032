<div class="front-forms">
    <div class="container-fluid">
        <div class="login_box">
            <div class="front-logo text-center">
                <img src="assets/img/logo.png" alt="KopShop" />
            </div>
            <form class="form-box" [formGroup]="forgotPasswordForm" (submit)="sendEmail()">
                <h2 class="text-center">Forgot Password</h2>
                <p class="text-center pb-3">Enter the email address associated <br />with your account.</p>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <img src="assets/img/icons/email.svg" alt="KopShop" width="22" />
                                    </span>
                                </div>
                                <input type="email" class="form-control" formControlName="email"
                                    placeholder="Enter email">
                            </div>
                            <div *ngIf="
                            forgotPasswordForm.controls['email'].hasError('required') &&
                            forgotPasswordForm.controls['email'].touched" class="error-box text-danger">
                                Please enter email
                            </div>
                            <div *ngIf="
                            forgotPasswordForm.controls['email'].hasError('pattern') &&
                            forgotPasswordForm.controls['email'].touched" class="text-danger error-box">
                                Please enter valid email
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="mt-2 mt-lg-3">
                            <button class="w-100 btn btn-lg btn-primary text-capitalize">Submit</button>
                        </div>
                    </div>
                </div>

                <div class="row pt-3">
                    <div class="col-12">
                        <div class="form-group text-center">
                            <a href="javascript:void(0)" [routerLink]="LINKS.SIGN_IN">Back to login</a>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>