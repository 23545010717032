<div class="card user_table default_block box_vh">
    <div
        class="card-header d-flex justify-content-start justify-content-md-between align-items-start align-items-md-center">
        <div class="breadcrumbs d-flex">
            <h3>General Information</h3>
        </div>
        <a href="javascript:void(0)" class="btn btn-md btn-primary" [routerLink]="LINKS.ADD_GENERAL_INFORMATION">Add
            New</a>
    </div>

    <div class="card-body" *ngIf="infos && infos.length > 0; else noData">
        <ul class="ek_list list-unstyled">
            <li class="position-relative" *ngFor="let item of infos">
                <div class="dots_drop">
                    <a href="#" class="actions" id="drop2" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <img src="assets/img/icons/dots_icon.svg" class="img-open" alt="" />
                        <img src="assets/img/icons/close_icon.svg" class="img-close" alt="" />
                    </a>
                    <div class="dropdown-menu shadow" aria-labelledby="drop2">
                        <i (click)="editInfo(item._id)"><img src="assets/img/icons/edit_icon.svg" alt="logout" /></i>
                        <i (click)="deleteInfo(item._id)"><img src="assets/img/icons/delete_icon.svg"
                                alt="logout" /></i>
                    </div>
                </div>
                <div (click)="openInfo(item._id, infoDetails)" class="info_body">
                    <h5> {{item.title}} </h5>
                    <p class="show-read-more">
                        {{item.description}}
                    </p>
                </div>
            </li>
        </ul>
    </div>
</div>

<ng-template #noData>
    <div *ngIf="!loading">
        <div class="card-body">
            <div class="d-flex align-items-center justify-content-center w-100 flex-column no-result">
                <!-- <i><img src="assets/img/icons/no-video_icon.svg" width="80" alt="" /></i> -->
                <h5 class="mt-3">No Information Added</h5>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #infoDetails>
    <div class="card">
        <div class="modal-header text-center">
            <button type="button" (click)="modal.dismiss('Cross click')" class="close"><img
                    src="assets/img/icons/close_icon.svg" alt="" /></button>
        </div>
        <div class="card-body">
            <h5 class="card-title">
                {{selectedInfo.title}}
            </h5>
            <p class="card-text info-modal-dec">
                {{selectedInfo.description}}
            </p>
        </div>
    </div>
</ng-template>