<div class="card user_table">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3 class="">Domains</h3>
        <button class="btn btn-md btn-primary" type="button" (click)="openModal(domainFormModal, false, null)">Add
            New</button>
    </div>
    <div class="card-body" *ngIf="domains && domains.length > 0; else noData">
        <div class="table-responsive">
            <table class="table table-hover" id="table_data" style="width:100%">
                <thead>
                    <tr>
                        <th>Domain Name
                            <!-- <div class="sorting">
                                <i class="active"><img src="assets/img/icons/arw_up.png" alt="sort" /></i>
                                <i><img src="assets/img/icons/arw_down.png" alt="sort" /></i>
                            </div> -->
                        </th>
                        <th class="text-right">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of domains">
                        <tr>
                            <td>{{item.domain}}</td>
                            <td class="text-right">
                                <div class="action_btn">
                                    <i class="edit_icon" (click)="openModal(domainFormModal, true, item)"><img
                                            src="assets/img/icons/edit_icon.svg" alt="" /></i>
                                    <i class="del_icon" (click)="deleteDomain(item._id)"><img
                                            src="assets/img/icons/delete_icon.svg" alt="" /></i>
                                </div>
                            <td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>

<ng-template #domainFormModal>
    <div class="modal-header text-center">
        <button type="button" class="close" (click)="hideModal()" aria-hidden="true"><img
                src="assets/img/icons/close_icon.svg" alt="" /></button>
        <h3 class="modal-title text-center" *ngIf="!editFlag">Add New Domain</h3>
        <h3 class="modal-title text-center" *ngIf="editFlag">Update Domain</h3>
    </div>
    <div class="modal-body">
        <form [formGroup]="domainForm" (submit)="submitOperation()">
            <div class="form-group mb-5">
                <label>Enter Domain</label>
                <input type="text" id="domainName" class="form-control" formControlName="domain" placeholder=".com">
                <div class="text-danger error_box"
                    *ngIf="domainForm.controls['domain'].hasError('required') && domainForm.controls['domain'].touched">
                    Please enter domain name
                </div>
            </div>
            <div class="btn-full">
                <button type="submit" [disabled]="loading" class="btn btn-lg btn-primary">
                    <span *ngIf="!loading">Submit</span>
                    <span *ngIf="loading">Submitting...</span>
                </button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #noData>
    <div *ngIf=!loading>
        <div class="card-body">
            <div class="d-flex align-items-center justify-content-center w-100 flex-column no-result">
                <i><img src="assets/img/icons/no-video_icon.svg" width="80" alt="" /></i>
                <h5 class="mt-3">No Results</h5>
            </div>
        </div>
    </div>
</ng-template>