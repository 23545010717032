import { SwalService } from './../../common/dialogue-services/swal.service';
import { AUTH_PATHS, ERROR_MSG, SUCCESS_MSG } from './../../constants';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'src/app/common/toastr/toastr.service';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-reset-password-admin',
  templateUrl: './reset-password-admin.component.html',
  styleUrls: ['./reset-password-admin.component.css']
})
export class ResetPasswordAdminComponent implements OnInit {

  token: string = null;
  invalid_token = false;
  content;

  FORM_ERRORS = {
    NEW_PASSWORD_REQUIRED: 'Please enter new password',
    CONFIRM_PASSWORD_REQUIRED: 'Please re-enter new password'
  }

  public resetPasswordForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private swal: SwalService
  ) {
    this.resetPasswordForm = this.fb.group({
      newPassword: ['', Validators.compose([Validators.required])],
      confirmPassword: ['', Validators.compose([Validators.required])]
    })
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.token = params['token'];
      this.verifyResetPasswordToken({ token: this.token });
    })
  }

  verifyResetPasswordToken(data) {
    this.authService.verifyResetPasswordToken(data).subscribe(res => {
      this.invalid_token = false;
    }, err => {
      this.invalid_token = true;
      this.content = err.error.msg;
    })
  }

  async resetPassword() {
    if (this.resetPasswordForm.valid) {
      if (this.resetPasswordForm.controls['newPassword'].value === this.resetPasswordForm.controls['confirmPassword'].value) {
        let swal_data = await this.swal.successSwal(SUCCESS_MSG.ADMIN.PASSWORD_RESET_SUCCESSFULLY);
        if (swal_data.value) {
          this.authService.resetPasswordAdmin({ token: this.token, newPassword: this.resetPasswordForm.controls['newPassword'].value }).subscribe(async res => {
            this.router.navigateByUrl(AUTH_PATHS.SIGN_IN);
            this.resetPasswordForm.reset();
          })
        }
      } else {
        this.toastrService.showToastr({ type: 'error', message: ERROR_MSG.PASSWORD_NOT_MATCHED })
      }
    } else {
      Object.keys(this.resetPasswordForm.controls).forEach(key => {
        this.resetPasswordForm.controls[key].markAsTouched({ onlySelf: true })
      })
    }
  }

}
