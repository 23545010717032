<div class="card user_table default_block box_vh">
    <div
        class="card-header d-flex justify-content-start justify-content-md-between align-items-start align-items-md-center">
        <div class="breadcrumbs d-flex">
            <h3 [routerLink]="LINKS.GENERAL_INFORMATION">General Information</h3>
            <span *ngIf="!editFlag"><i><img src="assets/img/icons/right_arrow_icon.svg" alt="" /></i> Add</span>
            <span *ngIf="editFlag"><i><img src="assets/img/icons/right_arrow_icon.svg" alt="" /></i> Edit</span>
        </div>
    </div>

    <div class="card-body">
        <div class="form_default py-3">
            <form [formGroup]="infoForm" (submit)="submitOperation()">
                <div class="form-group">
                    <label>Title</label>
                    <input type="text" class="form-control" maxlength="50" formControlName="title"
                        placeholder="Enter title">
                    <div class="error_box text-danger"
                        *ngIf="infoForm.controls['title'].hasError('required') && infoForm.controls['title'].touched ">
                        Please enter title
                    </div>
                </div>
                <div class="form-group">
                    <label>Description</label>
                    <textarea formControlName="description" class="form-control"
                        placeholder="Enter description"></textarea>
                    <div class="error_box text-danger"
                        *ngIf="infoForm.controls['description'].hasError('required') && infoForm.controls['description'].touched ">
                        Please enter description
                    </div>
                </div>
                <div class="text-center mt-4 mt-md-5">
                    <button type="submit" [disabled]="loading" class="btn btn-lg btn-primary">
                        <span *ngIf="!loading">Submit</span>
                        <span *ngIf="loading">Submitting...</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>