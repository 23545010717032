import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { AuthGuardService } from './core/services/auth-guard.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InternalModule } from './internal/internal.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrComponent } from './common/toastr/toastr.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResetPinComponent } from './auth/reset-pin/reset-pin.component';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';
import { ExternalAuthguardService } from './core/services/external-auth-guard.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './core/services/interceptor.service';
import { DeleteDialogueComponent } from './common/delete-dialogue/delete-dialogue.component';
import { ResetPasswordAdminComponent } from './auth/reset-password-admin/reset-password-admin.component';
import { AdminResetPasswordSuccessfullyComponent } from './auth/admin-reset-password-successfully/admin-reset-password-successfully.component';
import { LinkSentSuccessfullyComponent } from './auth/link-sent-successfully/link-sent-successfully.component';
import { LoaderComponent } from './common/loader/loader.component';

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    ForgotPasswordComponent,
    ResetPinComponent,
    ToastrComponent,
    PageNotFoundComponent,
    LoaderComponent,
    DeleteDialogueComponent,
    ResetPasswordAdminComponent,
    AdminResetPasswordSuccessfullyComponent,
    LinkSentSuccessfullyComponent
  ],
  imports: [
    AppRoutingModule,
    NgxSpinnerModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgbModule,
    InternalModule
  ],
  providers: [AuthGuardService, ExternalAuthguardService, {
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptorService,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
