<div class="card user_table default_block box_vh">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3 class="">Evidence Type</h3>
    </div>

    <div class="card-body" *ngIf="categoriesWithEvidenceCount && categoriesWithEvidenceCount.length !== 0; else noData">
        <div class="row">
            <ng-container *ngFor="let item of categoriesWithEvidenceCount">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12" (click)="navigateToCategory(item._id)">
                    <div
                        class="video_box d-flex align-items-center justify-content-center flex-column text-center purple">
                        <div class="col-12 p-0">
                            <div class="form-row align-items-center">
                                <i class="col-6"><img [src]="item.url" alt="" /></i>
                                <h6 class="col-6 m-0">Total items</h6>
                            </div>
                            <div class="form-row align-items-center">
                                <small class="col-6">{{item.name}}</small>
                                <span class="col-6">{{item.evidenceCount}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #noData>
    <div *ngIf=!loading>
        <div class="card-body">
            <div class="d-flex align-items-center justify-content-center w-100 flex-column no-result">
                <i><img src="assets/img/icons/no-video_icon.svg" width="80" alt="" /></i>
                <h5 class="mt-3">No Results</h5>
            </div>
        </div>
    </div>
</ng-template>