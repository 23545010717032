import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { INTERNAL_PATHS } from 'src/app/constants';
import { ApiService } from 'src/app/core/services/api.service';

@Component({
  selector: 'app-evidence-details',
  templateUrl: './evidence-details.component.html',
  styleUrls: ['./evidence-details.component.css']
})
export class EvidenceDetailsComponent implements OnInit {

  evidence;
  category;

  constructor(private route: ActivatedRoute, private apiService: ApiService, private router: Router) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.getEvidenceDetails(params.id);
    })
  }

  navigateToItemTypes() {
    let url = `/${INTERNAL_PATHS.ADMIN}/${INTERNAL_PATHS.CATEGORY_WISE_EVIDENCES}`;
    url = url.replace(':categoryId', this.category._id);
    this.router.navigateByUrl(url);
  }

  getCategoryDetail(id) {
    this.apiService.fetchCategoryById({ id }).subscribe(res => {
      this.category = res.data;
    })
  }

  getEvidenceDetails(id) {
    this.apiService.getEvidence(id).subscribe(res => {
      this.evidence = res.data;
      this.getCategoryDetail(this.evidence.categoryId);
    })
  }

}
