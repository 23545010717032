import { INTERNAL_PATHS } from 'src/app/constants';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-evidence',
  templateUrl: './evidence.component.html',
  styleUrls: ['./evidence.component.css']
})
export class EvidenceComponent implements OnInit {

  categoriesWithEvidenceCount;

  constructor(private apiService: ApiService, private router: Router) { }

  ngOnInit(): void {
    this.getCategoriesWithVideoCount()
  }

  getCategoriesWithVideoCount() {
    this.apiService.getCategoriesWithEvidenceCount().subscribe(res => {
      this.categoriesWithEvidenceCount = res.data;
    })
  }

  navigateToCategory(categoryId) {
    let url = INTERNAL_PATHS.CATEGORY_WISE_EVIDENCES;
    url = url.replace(':categoryId', categoryId);
    this.router.navigateByUrl(`${INTERNAL_PATHS.ADMIN}/${url}`);
  }

}
